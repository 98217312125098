import { z } from 'zod';

const loginFields = z.object({
  email: z.string({ required_error: 'Email required' }).email(),
  password: z
    .string({ required_error: 'Password required' })
    .min(8, { message: 'Password should be of minimum 8 characters length' })
    .refine((value) => /[a-z]+/.test(value), {
      message: 'Password must contain at least 1 lower case letter',
    })
    .refine((value) => /[A-Z]+/.test(value), {
      message: 'Password must contain at least 1 upper case letter',
    })
    .refine((value) => /[@$!%*#?&]+/.test(value), {
      message: 'Password must contain at least 1 special character',
    })
    .refine((value) => /\d+/.test(value), {
      message: 'Password must contain at least 1 number',
    }),
});

const LoginSchema = loginFields;

export type LoginValues = z.infer<typeof LoginSchema>;

export { LoginSchema };
