import cn from 'classnames';
import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import './button.css';
import { Spinner } from '../Spinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: any) => void;
  className?: string;
  variant: 'contained' | 'outlined' | 'text' | 'error' | 'outlined-black';
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  className,
  variant,
  children,
  disabled,
  loading,
  onClick,
  ...rest
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        'button-default relative',
        {
          'button-contained': variant === 'contained',
          'button-outlined': variant === 'outlined',
          'button-error': variant === 'error',
          'button-outlined-black': variant === 'outlined-black',
          'pointer-events-none': loading,
        },
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {loading && (
        <div className="absolute w-full h-full flex-center">
          <Spinner
            className={cn({
              '!text-white': variant === 'contained' || variant === 'error',
            })}
            remSize={1}
          />
        </div>
      )}
      <div className={cn({ 'opacity-0': loading })}>{children}</div>
    </button>
  );
};
