import { Icon, Text } from '@ui';
import { Icons } from '@utils';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { getRangePages } from './helpers/getRangePages';
import { useBreakpoints } from '@hooks';

import './pagination.css';

interface PaginationProps {
  className?: string;
  page: number;
  pageSize: number;
  count: number;
  setPage: (page: number) => void;
  onPageChange?: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  className,
  page,
  pageSize,
  count,
  setPage,
  onPageChange,
}) => {
  const pageCount = Math.ceil(count / pageSize);

  const { isDesktop } = useBreakpoints();
  const [rangePages, setRangePages] = useState<Array<number>>([]);

  useEffect(() => {
    setRangePages(getRangePages(page, pageCount));

    if (onPageChange) {
      onPageChange(page);
    }
  }, [page, pageCount]);

  return (
    <div
      className={cn(
        'flex items-center justify-between w-full l:w-fit text-grey-600',
        className
      )}
    >
      {isDesktop && (
        <Text variant="body2" className="mr-6">
          {`${page === 1 ? 1 : (page - 1) * pageSize + 1}
           - 
           ${pageCount === page ? count : page * pageSize}
           of ${count}`}
        </Text>
      )}

      {isDesktop && (
        <button
          type="button"
          className="flex-center l:brightness-50 disabled:brightness-100 disabled:opacity-30 disabled:hover:text-grey-600 hover:text-black-700"
          onClick={() => setPage(1)}
          disabled={page === 1 || pageCount < 1}
        >
          <Icon icon={Icons.ChevronLeftDouble} size={16} />
        </button>
      )}

      <button
        type="button"
        className="arrow-btn"
        onClick={() => setPage(page - 1)}
        disabled={page === 1 || pageCount < 1}
      >
        <Icon
          icon={isDesktop ? Icons.ChevronLeft : Icons.ArrowLeft}
          size={isDesktop ? 16 : 24}
        />
      </button>

      <div className="mx-2 flex-between gap-x-4 l:gap-x-3">
        {rangePages.map((pageNumber, index) =>
          pageNumber !== -1 ? (
            <button
              type="button"
              key={index}
              onClick={() => setPage(pageNumber)}
              className={cn('page-btn', {
                'hover:text-black-700 text-grey-600': pageNumber !== page,
                '!border-black-700': pageNumber === page,
              })}
            >
              <span
                className={cn('text-xs leading-[14px]', {
                  'text-black-700': pageNumber === page,
                })}
              >
                {pageNumber}
              </span>
            </button>
          ) : (
            <span key={index}>...</span>
          )
        )}
      </div>

      <button
        type="button"
        className="arrow-btn"
        onClick={() => setPage(page + 1)}
        disabled={page === pageCount || pageCount < 1}
      >
        <Icon
          icon={isDesktop ? Icons.ChevronRight : Icons.ArrowRight}
          size={isDesktop ? 16 : 24}
        />
      </button>

      {isDesktop && (
        <button
          type="button"
          className="flex-center disabled:opacity-30 disabled:brightness-100 l:brightness-50 disabled:hover:text-grey-600 hover:text-black-700"
          onClick={() => setPage(pageCount)}
          disabled={page === pageCount || pageCount < 1}
        >
          <Icon icon={Icons.ChevronRightDouble} size={16} />
        </button>
      )}
    </div>
  );
};
