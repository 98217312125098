import { createApi } from '@reduxjs/toolkit/query/react';

import { HTTPMethod, Response } from '../config';
import { baseQuery } from '../baseQuery';
import qs from 'qs';

import { Void, VoidsRequest, VoidsResponse } from './models';

export const VOIDS_LIMIT = 10;

export const voidsApi = createApi({
  baseQuery,
  reducerPath: 'voidsApi',
  tagTypes: ['Voids'],
  endpoints: (builder) => ({
    getVoids: builder.query<VoidsResponse, VoidsRequest>({
      query: ({ page, startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            limit: VOIDS_LIMIT,
            page,
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/voids?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
      providesTags: ['Voids'],
      transformResponse: (response: Response<Void[]>): VoidsResponse => {
        // console.log("ss", response); // Logging the data
        return {
          voids: response.data,
          total: response.pagination?.total_items || 0,
        };
      },
    }),
  }),
});

export const { useGetVoidsQuery } = voidsApi;
