import cn from 'classnames';
import React, { FC } from 'react';

interface SpinnerProps {
  remSize?: number;
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ remSize = 1.5, className }) => (
  <div
    className={cn('flex justify-center items-center text-blue-400', className)}
  >
    <div
      className={cn(
        `
          inline-block
          animate-spin rounded-full 
          border-[2px] border-solid border-current 
          border-r-transparent align-[-0.5em] 
          motion-reduce:animate-[spin_1.5s_linear_infinite]
      `
      )}
      role="status"
      style={{
        width: `${remSize}rem`,
        height: `${remSize}rem`,
      }}
    >
      <span
        className="
      !absolute !-m-px !h-px
      !w-px !overflow-hidden
      !whitespace-nowrap !border-0
      !p-0 ![clip:rect(0,0,0,0)]
    "
      >
        Loading...
      </span>
    </div>
  </div>
);
