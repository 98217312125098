import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import {
  HourlySalesReport,
  Login,
  ManageUsers,
  PaymentTypesReport,
  Root,
  SalesDashboard,
  StaffTimeTracker,
  TaxReport,
} from '@pages';

import { Routes } from './Routes';
import { ProtectedRoutes } from './ProtectedRoutes';

export const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    element: <Root />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: Routes.SALES_DASHBOARD,
            element: <SalesDashboard />,
          },
          {
            path: Routes.REPORTS,
            children: [
              {
                path: Routes.HOURLY_SALES_REPORT,
                element: <HourlySalesReport />,
              },
              {
                path: Routes.TAX_REPORT,
                element: <TaxReport />,
              },
              // {
              //   path: Routes.REFUND_REPORT,
              //   element: <RefundReport />,
              // },
              {
                path: Routes.PAYMENT_TYPES_REPORT,
                element: <PaymentTypesReport />,
              },
            ],
          },
          {
            path: Routes.MANAGE_USERS,
            element: <ManageUsers />,
          },
          {
            path: Routes.STAFF_TIME_TRACKER,
            element: <StaffTimeTracker />,
          },
        ],
      },
    ],
  },
  {
    path: Routes.LOGIN,
    element: <Login />,
  },
]);
