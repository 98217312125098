import { menuActions, menuSelectors } from '@store/entities';
import { useDispatch, useSelector } from '@store';
import { Icon } from '@ui';
import { Icons, Images } from '@utils';
import React, { FC, MutableRefObject } from 'react';

interface MobileHeaderProps {
  toggleBtnRef: MutableRefObject<null>;
}

export const MobileHeader: FC<MobileHeaderProps> = ({ toggleBtnRef }) => {
  const dispatch = useDispatch();

  const isOpenMenu = useSelector(menuSelectors.isOpen);

  const closeMenu = () => {
    dispatch(menuActions.closeMenu());
  };

  const openMenu = () => {
    dispatch(menuActions.openMenu());
  };

  return (
    <header className="fixed top-0 z-40 flex items-center justify-between w-full h-16 px-6 py-5 bg-white">
      <div className="flex items-center justify-center gap-x-3">
        <img src={Images.AmigoLogo} alt="amigo-logo" className="w-[66px] h-6" />

        <div className="w-px h-6 bg-black-700 opacity-20" />

        <img src={Images.Burrito} alt="amigo-logo" className="h-6 w-11" />
      </div>

      <button
        ref={toggleBtnRef}
        type="button"
        onClick={isOpenMenu ? closeMenu : openMenu}
        className="hover:opacity-70"
      >
        <Icon
          icon={isOpenMenu ? Icons.Close : Icons.Hamburger}
          className="text-black-700"
          size={isOpenMenu ? 14 : 16}
        />
      </button>
    </header>
  );
};
