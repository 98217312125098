export const Routes = {
  ROOT: '/',
  LOGIN: '/login',
  SALES_DASHBOARD: '/sales-dashboard',
  REPORTS: '/reports',
  HOURLY_SALES_REPORT: '/reports/hourly-sales-report',
  TAX_REPORT: '/reports/tax-report',
  // REFUND_REPORT: '/reports/refund-report',
  PAYMENT_TYPES_REPORT: '/reports/payment-types-report',
  MANAGE_USERS: '/manage-users',
  STAFF_TIME_TRACKER: '/staff-time-tracker',
} as const;

export type Route = (typeof Routes)[keyof typeof Routes];
