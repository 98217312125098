import React, { FC, ReactNode } from 'react';
import { Text } from '../Text';
import { useBreakpoints } from '@hooks';
import { Helmet } from 'react-helmet-async';
import { AllOrNothing } from '@types';
import cn from 'classnames';

type PageProps = {
  title: string;
  children: ReactNode;
  headContent?: ReactNode;
  wrapperClassName?: string;
} & AllOrNothing<{
  withIndicator: boolean;
  positive: boolean;
}>;

export const Page: FC<PageProps> = ({
  title,
  children,
  headContent,
  withIndicator,
  positive,
  wrapperClassName,
}) => {
  const { isDesktop } = useBreakpoints();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <main className="px-6 py-10 l:pt-0 l:pb-12 l:pl-[110px] l:pr-[120px]">
        <div className="flex-between">
          <Text
            variant={!isDesktop ? 'h3' : 'h1'}
            className="relative !font-bold"
          >
            {title}
          </Text>

          {headContent}
        </div>

        <div className={cn('mt-6 l:mt-8', wrapperClassName)}>{children}</div>
      </main>
    </>
  );
};
