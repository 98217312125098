import { createApi } from '@reduxjs/toolkit/query/react';

import { HTTPMethod, Response } from '../config';
import { baseQuery } from '../baseQuery';
import qs from 'qs';

import {
  OrdersStatistics,
  OrdersRequest,
  OrdersResponse,
  Order,
  UpdateOrderRequest,
  OrdersSalesResponse,
  OrderSale,
  OrdersStatisticsRequest,
  OrdersStatisticsResponse,
  OrderDetailsRequest,
  OrderDetailsResponse,
  OrdersCSVRequest,
} from './models';
import { DateRangeFilters } from '../models';

export const ORDERS_LIMIT = 10;

export const ordersApi = createApi({
  baseQuery,
  reducerPath: 'ordersApi',
  tagTypes: ['Orders'],
  endpoints: (builder) => ({
    getOrders: builder.query<OrdersSalesResponse, OrdersRequest>({
      query: ({ page, startDate, endDate, locations, limit }) => {
        const queryString = qs.stringify(
          {
            limit: limit || ORDERS_LIMIT,
            page,
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/orders?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
      providesTags: ['Orders'],
      transformResponse: (
        response: Response<OrderSale[]>
      ): OrdersSalesResponse => {
        // console.log("ss", response); // Logging the data
        return {
          orders: response.data,
          total: response.pagination?.total_items || 0,
        };
      },
    }),
    updateOrder: builder.mutation<Order, UpdateOrderRequest>({
      query: ({ id, state }) => ({
        url: `/orders/${id}`,
        method: HTTPMethod.PATCH,
        body: { state },
      }),
      invalidatesTags: ['Orders'],
      transformResponse: (response: Response<Order>) => response.data,
    }),
    getOrdersStatistics: builder.query<
      OrdersStatistics,
      OrdersStatisticsRequest
    >({
      query: ({ startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/summary_statistics?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
      providesTags: ['Orders'],
      transformResponse: (
        response: OrdersStatisticsResponse
      ): OrdersStatistics => {
        // console.log("ss", response); // Logging the data
        return {
          totalSales: response.total_sales,
          salesVolume: response.number_of_orders,
          averageTicketValue: response.average_ticket_value,
        };
      },
    }),
    getOrderDetails: builder.query<OrderDetailsResponse, OrderDetailsRequest>({
      query: ({ locationId, orderId }) => {
        return {
          url: '/order_details',
          method: HTTPMethod.GET,
          params: {
            location_id: locationId,
            order_id: orderId,
          },
        };
      },
      providesTags: ['Orders'],
    }),
    getOrdersCSV: builder.mutation<string, OrdersCSVRequest>({
      query: ({ startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/orders_csv?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useUpdateOrderMutation,
  useGetOrdersStatisticsQuery,
  useGetOrderDetailsQuery,
  useGetOrdersCSVMutation,
} = ordersApi;
