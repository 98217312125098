import { useClickAway } from '@hooks';
import cn from 'classnames';
import React, { FC, MutableRefObject, useRef, useState } from 'react';
import { Text } from '../Text';
import { Icon } from '../Icon';
import { Icons } from '@utils';

interface SortByProps {
  refsArray: MutableRefObject<HTMLButtonElement | null>[];
  columns: string[];
  className?: string;
}

export const SortBy: FC<SortByProps> = ({ refsArray, className, columns }) => {
  const [isShowOptions, setShowOptions] = useState(false);

  const buttonRef = useRef(null);
  const ref = useClickAway(() => setShowOptions(false), [buttonRef]);

  const initClicks = refsArray.map(() => 0);
  const [optionsClicks, setOptionClicks] = useState<number[]>(initClicks);

  const onClick = (
    optionRef: MutableRefObject<HTMLButtonElement | null>,
    optionIndex: number
  ) => {
    if (optionRef?.current) {
      optionRef.current.click();
    }

    const newOptionsClicks = optionsClicks.map((el, index) => {
      if (index === optionIndex) {
        return el !== 2 ? el + 1 : 0;
      }

      return 0;
    });

    setOptionClicks(newOptionsClicks);
  };

  return (
    <div className={cn('relative w-full l:w-fit', className)}>
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setShowOptions(!isShowOptions)}
        className={cn('flex-between gap-x-2.5', {
          'opacity-70': isShowOptions,
        })}
      >
        <Text variant="body2" className="!font-semibold">
          Sort by
        </Text>

        <Icon icon={Icons.Menu} size={16} />
      </button>

      {isShowOptions && (
        <ul
          ref={ref}
          className="absolute left-0 z-40 py-1 bg-white rounded-b shadow-lg w-fit l:min-w-fit top-6"
        >
          {refsArray.map((optionRef, index) => (
            <li
              key={columns[index]}
              className="cursor-pointer hover:bg-black-700 hover:bg-opacity-20 active:opacity-80"
            >
              <button
                type="button"
                onClick={() => onClick(optionRef, index)}
                className={cn(
                  'list-item px-3 py-2 h-[30px] text-[12px] w-full text-left whitespace-nowrap',
                  {
                    '!font-semibold': optionsClicks[index] > 0,
                  }
                )}
              >
                {columns[index]}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
