import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod } from '../config';
import {
  HourlySalesResponse,
  PaymentTypesElement,
  ReportFilters,
  TaxReportResponse,
} from './models';
import qs from 'qs';

export const TAX_REPORTS_LIMIT = 8;
export const REFUND_REPORTS_LIMIT = 6;
export const PAYMENT_TYPES_LIMIT = 10;
export const HOURLY_SALES_LIMIT = 24;

export const reportsApi = createApi({
  baseQuery,
  reducerPath: 'reportsApi',
  endpoints: (builder) => ({
    getPaymentTypesReport: builder.query<PaymentTypesElement[], ReportFilters>({
      query: ({ startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/payment_types?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
    }),
    getHourlySalesReport: builder.query<
      {
        [key: string]: { [key: string]: number };
      },
      ReportFilters
    >({
      query: ({ startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/hourly_sales?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
      transformResponse: (response: {
        [key: string]: { [key: string]: number };
      }) => {
        const hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        const hourlySalesData: {
          [key: string]: string | number;
          total: number;
        }[] = [
          ...hours.map((hour) => ({ time: `${hour} am`, total: 0 })),
          ...hours.map((hour) => ({ time: `${hour} pm`, total: 0 })),
        ];

        const sources: string[] = [];
        const timeKeys = Object.keys(response);

        timeKeys.forEach((timeKey) => {
          const sourceKeys = Object.keys(response[timeKey]);
          sourceKeys.forEach((sourceKey) => {
            if (!sources.includes(sourceKey)) {
              sources.push(sourceKey);
            }
          });
        });

        hourlySalesData.forEach((hourlySalesItem) => {
          sources.forEach((source) => {
            // eslint-disable-next-line no-param-reassign
            hourlySalesItem[source] =
              response[hourlySalesItem.time as string]?.[source] || 0;

            // eslint-disable-next-line no-param-reassign
            hourlySalesItem.total += (hourlySalesItem[source] as number) || 0;
          });
        });

        const dataResponse = {
          data: hourlySalesData,
          domains: sources,
        };
        return dataResponse as any;
      },
    }),
    getTaxReport: builder.query<TaxReportResponse, ReportFilters>({
      query: ({ startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/tax_summary?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
    }),
    getTaxReportCSV: builder.mutation<string, ReportFilters>({
      query: (filters) => {
        return {
          url: '/reports/taxes/csv',
          method: HTTPMethod.GET,
          params: {
            filters,
          },
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const {
  useGetPaymentTypesReportQuery,
  useGetHourlySalesReportQuery,
  useGetTaxReportQuery,
  useGetTaxReportCSVMutation,
} = reportsApi;
