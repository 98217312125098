import { RefundReport } from '@api';
import { RefundReason } from '@types';

export const refundTableData: RefundReport[] = [
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
  {
    time: '26/09/2023 10:15 AM',
    platform: 'Website',
    orderId: 4252525,
    ticket: 'T123',
    refundAmount: 20,
    refundReason: RefundReason.WRONG_ITEM,
    total: 540.45,
  },
];
