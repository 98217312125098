import { formatNumber } from '@utils';
import { Text } from '@ui';
import cn from 'classnames';
import React, { FC } from 'react';

interface SalesMetricProps {
  title: string;
  value: number;
  differenceValue?: number;
  prefix?: string;
}

export const SalesMetric: FC<SalesMetricProps> = ({
  title,
  value,
  differenceValue,
  prefix = '',
}) => {
  return (
    <div className="flex flex-col items-start justify-between gap-y-2">
      <Text variant="body2" className="opacity-80">
        {title}
      </Text>

      <Text variant="h2" className="!font-medium">
        {prefix}
        {formatNumber(value)}
      </Text>

      {/* <Text
        variant="body2"
        className={cn('!font-medium ', {
          'text-success': differenceValue > 0,
          'text-error': differenceValue < 0,
        })}
      >
        {differenceValue < 0 ? '-' : '+ '}
        {prefix}
        {formatNumber(Math.abs(differenceValue))}
        <span className="font-normal text-black-700"> since last week</span>
      </Text> */}
    </div>
  );
};
