import { z } from 'zod';

const addUserFields = z.object({
  // fullName: z.string(),
  email: z.string().email(),
  // position: z.string(),
  // locations: z.string().array(),
});

const AddUserSchema = addUserFields;

export type AddUserValues = z.infer<typeof AddUserSchema>;

export { AddUserSchema };
