import { useDispatch, useSelector } from '@store';
import { filtersActions, filtersSelectors } from '@store/entities';
import { DateRange, DateRangeOption } from '@types';
import { DateRangePicker, Select } from '@ui';
import { calculateEndDate, calculateStartDate, dateRangeOptions } from '@utils';
import React, { FC } from 'react';

export const DateRangeFilter: FC = () => {
  const dispatch = useDispatch();
  const dateRange = useSelector(filtersSelectors.dateRangeFilter);

  const selectedDateRange = useSelector(filtersSelectors.selectedDateRange);

  const onChangeSelect = (newValue: DateRangeOption) => {
    dispatch(filtersActions.setDateRange(newValue));

    if (newValue !== 'custom-date-range') {
      const startDate = new Date(calculateStartDate(newValue).unix() * 1000);
      const endDate = new Date(calculateEndDate(newValue).unix() * 1000);

      dispatch(
        filtersActions.setFilters({
          from: startDate,
          to: endDate,
        })
      );
    }
  };

  const onChangeDateRange = (newValue: DateRange) => {
    const { from, to } = newValue;

    dispatch(
      filtersActions.setFilters({
        from,
        to,
      })
    );
  };

  return (
    <div className="flex flex-col items-start justify-start w-full l:w-fit gap-y-2 l::mr-auto l:flex-row gap-x-4">
      <Select
        label="Date range"
        value={selectedDateRange}
        onChange={onChangeSelect}
        options={dateRangeOptions}
        labelClassName="!mb-2 l:!mb-1"
      />

      {selectedDateRange === 'custom-date-range' && (
        <DateRangePicker value={dateRange} onChange={onChangeDateRange} />
      )}
    </div>
  );
};
