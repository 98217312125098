import React, { FC, PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import './style.css';
import cn from 'classnames';

import { Icon } from '../Icon';
import { Icons } from '@utils';
import { Text } from '../Text';

export interface ModalProps {
  isOpen: boolean;
  className?: string;
  title?: string;
  headerClassName?: string;
  overlayClassName?: string;
  onClose: () => void;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  isOpen,
  className,
  title,
  onClose,
  overlayClassName,
  children,
  headerClassName,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={cn(
        'react-modal-content custom-scroll py-8 px-6 l:px-10 outline-none',
        className
      )}
      overlayClassName={cn('react-modal-overlay', overlayClassName)}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className={cn('flex-between gap-x-10', headerClassName)}>
        <Text variant="h4" className="font-semibold">
          {title}
        </Text>

        <button
          type="button"
          onClick={onClose}
          className="hover:brightness-75 text-black-700"
        >
          <Icon icon={Icons.Close} className="mb-1" size={14} />
        </button>
      </div>

      {children}
    </ReactModal>
  );
};
