import { Text } from '@ui';
import { chartColors, firstCapitalLetter, formatNumber } from '@utils';
import React, { FC } from 'react';
import { TooltipProps, XAxisProps, YAxisProps } from 'recharts';

interface CustomTooltipProps extends TooltipProps<number, string> {
  prefix?: string;
  valuePrefix?: string;
  domain: string[];
}

export const CustomTooltip: FC<CustomTooltipProps> = ({
  active,
  payload,
  prefix = 'Sales: ',
  valuePrefix = '$',
  domain,
}) => {
  if (active && payload && payload.length) {
    const sourceSales: { [key: string]: number } = {};
    domain.forEach((source) => {
      sourceSales[source] = 0;
    });

    payload.forEach((item) => {
      domain.forEach((source) => {
        if (item?.payload?.[source]) {
          sourceSales[source] = item?.payload?.[source];
        }
      });
    });
    return (
      <div className="relative z-50  px-2 text-[12px] text-white bg-black-700 rounded flex-center flex-col">
        {domain.map((source) => (
          <div key={source}>
            {`${source}: `}
            {valuePrefix}
            {formatNumber(Number(sourceSales[source]))}
          </div>
        ))}
        <div className="absolute w-4 h-4 rotate-45 left-1/3 -bottom-1 -z-10 bg-black-700" />
      </div>
    );
  }

  return null;
};

interface CustomXAxisTickProps extends XAxisProps {
  payload?: {
    value: number;
  };
}

export const CustomXAxisTick: FC<CustomXAxisTickProps> = ({
  x,
  y,
  payload,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={25}
        dx={15}
        textAnchor="end"
        fill="#9599A0"
        fontFamily="Poppins, system-ui, sans-serif"
        fontSize={12}
        fontWeight={400}
      >
        {payload?.value}
      </text>
    </g>
  );
};

interface CustomYAxisTickProps extends YAxisProps {
  payload?: {
    value: number;
  };
  prefix?: string;
}

export const CustomYAxisTick: FC<CustomYAxisTickProps> = ({
  x,
  y,
  payload,
  prefix = '',
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#9599A0"
        fontFamily="Poppins, system-ui, sans-serif"
        fontSize={12}
        fontWeight={400}
      >
        {prefix}
        {formatNumber(Number(payload?.value))}
      </text>
    </g>
  );
};

interface CustomLegendProps {
  domain: string[];
}

export const Legend: FC<CustomLegendProps> = ({ domain }) => (
  <div className="absolute w-fit h-[27px] px-4 border rounded-full flex-center gap-x-4 top-6 right-6 z-10 bg-grey-100 border-grey-400">
    {domain.map((group, index) => (
      <span key={group} className="flex items-center justify-start gap-x-2">
        <div
          className="h-2.5 w-2.5 rounded-full"
          style={{
            backgroundColor: chartColors[index],
          }}
        />
        {firstCapitalLetter(group)}
      </span>
    ))}
  </div>
);

export const PieChartLegend: FC<{ labels: string[] }> = ({ labels }) => (
  <ul className="flex flex-wrap justify-center gap-y-3 gap-x-4">
    {labels?.map((label, index) => (
      <li key={index} className="flex items-center justify-start gap-x-2">
        <div
          className="h-2.5 w-2.5 rounded-full"
          style={{
            backgroundColor: chartColors[index],
          }}
        />
        <Text variant="body1" className="!text-[16px] leading-5 !font-medium">
          {firstCapitalLetter(label)}
        </Text>
      </li>
    ))}
  </ul>
);

export const CustomPieTooltip = ({
  active,
  payload,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-center custom-tooltip">
        <Text variant="h1">{`$${formatNumber(Number(payload[0].value))}`}</Text>
        <span className="text-base opacity-50 text-black-700">{`${payload[0].name}`}</span>
      </div>
    );
  }

  return null;
};
