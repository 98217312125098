import { FiltersState } from './types';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';

const selectedDateRange = (state: FiltersState) =>
  state.filters.selectedDateRange;

const dateRangeFilter = (state: FiltersState) => state.filters.dateRangeFilter;

const unixDateRangeFilter = createSelector([dateRangeFilter], (dateRange) => ({
  startDate: dayjs(dateRange.from).unix(),
  endDate: dayjs(dateRange.to).unix(),
}));

const selectedLocations = (state: FiltersState) =>
  state.filters.selectedLocations;

export const filtersSelectors = {
  unixDateRangeFilter,
  dateRangeFilter,
  selectedDateRange,
  selectedLocations,
};
