import { CustomPieTooltip, PieChartLegend } from '@features/Custom';
import { Dimensions } from '@types';
import { chartColors } from '@utils';
import React from 'react';
import {
  PieChart as Chart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

interface PieChartProps<ChartElement> {
  dimensions: Dimensions;
  data: ChartElement[];
  dataKey: keyof ChartElement;
  nameKey: keyof ChartElement;
}

export const PieChart = <ChartElement extends object>({
  dimensions,
  data,
  dataKey,
  nameKey,
}: PieChartProps<ChartElement>) => {
  const labels = data?.map((d) => d[nameKey]) as string[];

  return (
    <div className="w-full h-full">
      <ResponsiveContainer width={dimensions.width} height={dimensions.height}>
        <Chart>
          <Tooltip
            wrapperClassName="w-full"
            content={<CustomPieTooltip />}
            position={{ x: 0, y: 0 }}
            wrapperStyle={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />

          <Pie
            data={data}
            innerRadius="65%"
            outerRadius="80%"
            cornerRadius="100%"
            fill="#8884d8"
            paddingAngle={5}
            dataKey={dataKey as string}
            nameKey={nameKey as string}
          >
            {data.map((_, index) => (
              <Cell
                key={index}
                fill={chartColors[index % chartColors.length]}
              />
            ))}
          </Pie>
        </Chart>
      </ResponsiveContainer>

      <PieChartLegend labels={labels} />
    </div>
  );
};
