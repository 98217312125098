export const Icons = {
  ArrowLeft: 'arrow-left',
  ArrowLeftRight: 'arrow-left-right',
  ArrowRight: 'arrow-right',
  ArrowDown: 'arrow-down',
  BarChart: 'bar-chart',
  Calendar: 'calendar',
  ChevronLeft: 'chevron-left',
  ChevronRight: 'chevron-right',
  ChevronLeftDouble: 'chevron-left-double',
  ChevronRightDouble: 'chevron-right-double',
  Clock: 'clock',
  Close: 'close',
  Discount: 'discount',
  DiscountGear: 'discount-gear',
  Document: 'document',
  Dollar: 'dollar',
  Eye: 'eye',
  Exit: 'exit',
  Hamburger: 'hamburger',
  Menu: 'menu',
  Search: 'search',
  Shape: 'shape',
  Settings: 'settings',
  Pencil: 'pencil',
  Line: 'line',
  Trash: 'trash',
} as const;

export type IconType = (typeof Icons)[keyof typeof Icons];
