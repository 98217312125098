import {
  Button,
  CheckboxSelector,
  Input,
  Modal,
  ModalProps,
  Select,
} from '@ui';
import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { AddUserSchema, AddUserValues } from '../../helpers/validation';
import { positionOptions } from '../../config';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import {
  User,
  UserPosition,
  useCreateUserMutation,
  useGetLocationsQuery,
  useUpdateUserMutation,
} from '@api';
import { useToast } from '@hooks';
import { getUpdatedFields } from '@utils';

interface UserModalProps extends ModalProps {
  variant: 'add' | 'edit';
  userDetails?: User;
}

export const UserModal: FC<UserModalProps> = ({
  isOpen,
  variant,
  userDetails,
  onClose,
}) => {
  const { showErrorToast, showSuccessToast } = useToast();
  const isEdit = variant === 'edit';

  const { data: locations, isLoading } = useGetLocationsQuery();
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const form = useFormik<AddUserValues>({
    initialValues: {
      // fullName: userDetails?.name || '',
      email: userDetails?.email || '',
      // position: userDetails?.position || '',
      // locations: userDetails?.locations.map((el) => el.name) || [],
    },
    validationSchema: toFormikValidationSchema(AddUserSchema),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        // const selectedLocations = locations?.filter((el) =>
        //   values.locations.includes(el.name)
        // );

        const body = {
          // name: values.fullName,
          email: values.email,
          // position: values.position as UserPosition,
          // locations: selectedLocations,
        };

        if (isEdit && userDetails) {
          const updatedFields = getUpdatedFields(userDetails, body);

          // await updateUser({
          //   id: userDetails?.id,
          //   body: {
          //     ...updatedFields,
          //     locations: updatedFields.locations?.map((el) => el.id),
          //   },
          // }).unwrap();

          showSuccessToast('User updated successfully');
        } else {
          await createUser({
            ...body,
            // locations: selectedLocations?.map((el) => el.id) || [],
            password: process.env.REACT_APP_MOCKED_PASSWORD,
          }).unwrap();

          showSuccessToast('User created successfully');
        }

        onClose();
        resetForm();
      } catch {
        showErrorToast();
      }
    },
  });

  const { isValid, values, isSubmitting, dirty, setFieldValue, handleSubmit } =
    form;

  const formattedLocations = locations?.map((location) => location.name);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? 'Edit user information' : 'Add new user'}
      className="w-full max-w-[400px]"
    >
      <FormikProvider value={form}>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mt-6 gap-y-4">
            {/* <Input<AddUserValues>
              fieldName="fullName"
              label="Full Name"
              placeholder="Enter Full Name"
            /> */}
            <Input<AddUserValues>
              fieldName="email"
              label="Email"
              placeholder="Enter Email"
            />
            {/* <Select
              label="Position"
              placeholder="Position"
              value={values.position}
              onChange={(value) => setFieldValue('position', value)}
              options={positionOptions}
              className="!w-full"
              formSelect
            />
            <CheckboxSelector<AddUserValues>
              fieldName="locations"
              options={formattedLocations}
              label="Locations"
              placeholder="Locations"
              loading={isLoading}
            /> */}
          </div>

          <Button
            variant="contained"
            type="submit"
            className="w-full mt-6 l:w-auto l:ml-auto"
            loading={isSubmitting}
            disabled={!isValid || (isEdit && !dirty)}
          >
            Confirm
          </Button>
        </form>
      </FormikProvider>
    </Modal>
  );
};
