import React, { FC, ReactNode, useState } from 'react';
import Collapsible, { CollapsibleProps } from 'react-collapsible';
import { Icon } from '../Icon';
import { IconType, Icons } from '@utils';
import { NavAccordionHeader } from './ui';

interface AccordionProps extends CollapsibleProps {
  children: ReactNode;
  headerTitle: string;
  headerIcon: IconType;
}

export const Accordion: FC<AccordionProps> = ({
  children,
  headerTitle,
  headerIcon,
  ...rest
}) => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  const onToggleAccordionOpen = () => setAccordionOpen(!isAccordionOpen);

  const icon = (
    <button
      type="button"
      onClick={onToggleAccordionOpen}
      className="cursor-pointer chevron !right-8 l:!right-2.5"
    >
      <Icon icon={Icons.ArrowDown} className="text-black-700" />
    </button>
  );

  return (
    <Collapsible
      open={isAccordionOpen}
      transitionTime={100}
      triggerSibling={icon}
      handleTriggerClick={onToggleAccordionOpen}
      {...rest}
      trigger={
        rest.trigger || (
          <NavAccordionHeader title={headerTitle} icon={headerIcon} />
        )
      }
    >
      {children}
    </Collapsible>
  );
};
