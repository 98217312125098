// import { createApi } from '@reduxjs/toolkit/query/react';
//
// import { baseQuery } from '../baseQuery';
// import { HTTPMethod, Response } from '../config';
// import { User } from '../models';
// import { LoginRequest } from './models';
//
// export const authApi = createApi({
//   baseQuery,
//   reducerPath: 'authApi',
//   endpoints: (builder) => ({
//     login: builder.mutation<Response<User>, LoginRequest>({
//       query: (body) => ({
//         url: '/auth/sign-in',
//         method: HTTPMethod.POST,
//         body,
//       }),
//     }),
//     logout: builder.mutation<void, void>({
//       query: () => ({
//         url: '/auth/sign-out',
//         method: HTTPMethod.POST,
//       }),
//     }),
//   }),
// });
//
// export const { useLoginMutation, useLogoutMutation } = authApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuery';
import { HTTPMethod, Response } from '../config';
import { User } from '../models';
import { LoginRequest } from './models';
import { filtersActions, userActions } from '@store/entities'; // Import the action to set selected locations
// import { locationsActions } from '@store/entities'; // Import the action to set locations

export const authApi = createApi({
  baseQuery,
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    login: builder.mutation<Response<User>, LoginRequest>({
      query: (body) => ({
        url: '/auth/sign-in',
        method: HTTPMethod.POST,
        body,
      }),
      // Add an 'onQueryStarted' lifecycle event
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Assuming the data contains a 'locations' field
          localStorage.setItem(
            'token',
            JSON.stringify({
              ...data,
              email: args.email,
            })
          );
          dispatch(
            userActions.setUserData({
              email: args.email,
            })
          );
          if (data.locations) {
            // console.log("hi", data.locations)
            dispatch(
              filtersActions.setSelectedLocations(data.locations as any)
            );
          }
          // if (data.locations) {
          //   dispatch(filtersActions.setLocations(data.locations as any));
          // }
        } catch (error) {
          console.log(error);
          // Handle error, if necessary
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/sign-out',
        method: HTTPMethod.POST,
      }),
    }),
    // ... other endpoints
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
