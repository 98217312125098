import { createApi } from '@reduxjs/toolkit/query/react';

import { HTTPMethod, Response } from '../config';
import { baseQuery } from '../baseQuery';

import { Location } from './models';

export const locationsApi = createApi({
  baseQuery,
  reducerPath: 'locationsApi',
  endpoints: (builder) => ({
    getLocations: builder.query<Location[], void>({
      query: () => {
        return {
          url: '/locations',
          method: HTTPMethod.GET,
        };
      },
      transformResponse: (response: Response<Location[]>) => response.data,
    }),
  }),
});

export const { useGetLocationsQuery } = locationsApi;
