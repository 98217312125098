import React, { FC } from 'react';
import { Text } from '../Text';
import cn from 'classnames';

interface LabelProps {
  text: string;
  className?: string;
}

export const Label: FC<LabelProps> = ({ text, className }) => {
  return (
    <Text
      variant="body2"
      className={cn('!font-medium text-grey-650 !text-[10px]', className)}
    >
      {text}
    </Text>
  );
};
