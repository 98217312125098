/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway } from '@hooks';
import { DateFormats, Icons, formatDate } from '@utils';
import { DayPicker, Matcher } from 'react-day-picker';
import cn from 'classnames';

import { Icon } from '../../Icon';

import { Caption, DayContent } from '../Custom';

import 'react-day-picker/dist/style.css';
import './style.css';
import { Text } from '../../Text';
import { Label } from '../../Label';

const today = new Date();

interface DatePickerProps {
  label?: string;
  value: Date;
  disableDaysBefore?: Date;
  disableDaysAfter?: Date;
  withNoBorder?: boolean;
  className?: string;
  onChange: (date: Date) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  disableDaysBefore,
  disableDaysAfter,
  withNoBorder,
  className,
  onChange,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [date, setDate] = useState<Date | undefined>(value);
  const [isPickerVisible, setPickerVisible] = useState(false);

  useEffect(() => {
    onChange(date as Date);
  }, [date]);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const ref = useClickAway(() => setPickerVisible(false), [buttonRef]);

  const selectedDateFormatted = formatDate(
    date || new Date(),
    DateFormats.AMERICAN_FORMAT
  );

  const disabledProps = useMemo(() => {
    if (!disableDaysBefore && !disableDaysAfter) return false;
    const disabledObj: Matcher = false;
    if (disableDaysBefore && disableDaysAfter) {
      return { before: disableDaysBefore, after: disableDaysAfter };
    }
    if (disableDaysBefore) {
      return { before: disableDaysBefore };
    }
    if (disableDaysAfter) {
      return { after: disableDaysAfter };
    }
    return disabledObj;
  }, [disableDaysBefore, disableDaysAfter]);

  return (
    <div className="relative w-min">
      {label && <Label text={label} className="mb-2 l:mb-1" />}

      <button
        ref={buttonRef}
        type="button"
        onClick={() => setPickerVisible(!isPickerVisible)}
        className={cn(
          'relative w-min h-12 l:h-8 bg-white border border-borderColor rounded-[5px] shadow-input-shadow',
          {
            '!border-none': withNoBorder,
          },
          className
        )}
      >
        <div className="flex flex-row items-center justify-between px-3 ">
          <div className="flex justify-between items-center gap-x-1.5">
            <div>
              <Icon icon={Icons.Calendar} size={16} className="text-grey-600" />
            </div>

            <Text variant="body2" className="uppercase mt-0.5">
              {selectedDateFormatted}
            </Text>
          </div>
        </div>
      </button>

      {isPickerVisible && (
        <div
          ref={ref}
          className={cn(
            'absolute z-20 top-[44px] px-6 py-5 rounded-[10px] bg-white shadow-md',
            { 'top-[65px]': !!label }
          )}
        >
          <DayPicker
            disabled={disabledProps}
            mode="single"
            weekStartsOn={1}
            onSelect={setDate}
            selected={date}
            components={{ Caption, DayContent }}
            defaultMonth={today}
          />
        </div>
      )}
    </div>
  );
};
