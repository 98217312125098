import { useGetOrderDetailsQuery } from '@api';
import { Modal, ModalProps, Spinner } from '@ui';
import { formatNumber } from '@utils';
import classNames from 'classnames';
import React, { FC } from 'react';

interface OrderDetailsModalProps extends ModalProps {
  orderId: number;
  locationId: number;
}

export const OrderDetailsModal: FC<OrderDetailsModalProps> = ({
  isOpen,
  orderId,
  locationId,
  onClose,
}) => {
  const { data, isLoading, isFetching } = useGetOrderDetailsQuery({
    orderId,
    locationId,
  });

  return (
    <Modal
      title={`Order #${orderId}`}
      isOpen={isOpen}
      onClose={onClose}
      className="w-full max-w-[560px] !px-4 py-6 l:!py-8 l:!px-10"
    >
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <div className="mt-4 border-t-2 border-zinc-300 pt-4">
          <div className="flex flex-col gap-4">
            {(data?.items || []).map((item, i) => (
              <div
                key={item.name}
                className={classNames(
                  data &&
                    data.items.length - 1 !== i &&
                    'border-b-2 pb-4 border-zinc-300'
                )}
              >
                <div className="flex justify-between pb-4 border-b-2 border-zinc-300">
                  <p className="text-xl font-bold">{item.name}</p>
                  <div className="text-right">
                    <p>${formatNumber(item.price)} each</p>
                    <p>
                      <span className="text-gray-500">Total</span> $
                      {formatNumber(item.price * item.quantity)}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col mt-4">
                  {item.modifiers.map((modifier) => (
                    <div key={modifier.name} className="capitalize">
                      {modifier.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 flex flex-col gap-2 pb-4 border-zinc-300 border-b-2">
            <div className="flex justify-between">
              <div className="text-gray-500">Subtotal</div>
              <div className="text-black">
                ${formatNumber(data?.subtotal_amount || 0)}
              </div>
            </div>
            {data && data.discount_amount > 0 && (
              <div className="flex justify-between">
                <div className="text-gray-500">Discount</div>
                <div className="text-red-400">
                  - ${formatNumber(data?.discount_amount || 0)}
                </div>
              </div>
            )}
            <div className="flex justify-between">
              <div className="text-gray-500">Tax</div>
              <div className="text-black">
                ${formatNumber(data?.tax_amount || 0)}
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-between text-black font-medium">
            <div>Total</div>
            <div>${formatNumber(data?.total_amount || 0)}</div>
          </div>
        </div>
      )}
    </Modal>
  );
};
