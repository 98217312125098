/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */

import { createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  LoaderContainer,
  Page,
  Pagination,
  SearchInput,
  SortBy,
  Spinner,
  Table,
  Text,
} from '@ui';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { DeleteModal, LocationLabel, UserModal } from './ui';
import { LocationModal } from './ui/LocationModal';
import { useBreakpoints, useToast } from '@hooks';
import {
  USERS_LIMIT,
  User,
  useGetUsersCSVMutation,
  useGetUsersQuery,
} from '@api';
import { match } from 'ts-pattern';
import { DateFormats, downloadCSV, formatDate } from '@utils';

type UserModalVariant = 'add' | 'edit';

export const ManageUsers: FC = () => {
  const { isDesktop } = useBreakpoints();
  const { showErrorToast } = useToast();

  const [isLocationsModalOpen, setLocationsModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState(search);

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: usersData,
    isLoading,
    isFetching,
  } = useGetUsersQuery({
    page: currentPage,
    search: delayedSearch,
  });

  const [exportCV, { isLoading: isCsvLoading }] = useGetUsersCSVMutation();

  const [userModalVariant, setUserModalVariant] =
    useState<UserModalVariant>('add');

  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const columnHelper = createColumnHelper<User>();

  const onOpenLocationsModal = (index: number) => {
    setActiveRowIndex(index);

    setLocationsModalOpen(true);
  };

  const columns = useMemo(
    () => [
      // columnHelper.accessor((row) => row.name, {
      //   header: 'NAME',
      //   cell: (cell) => cell.getValue() || '-',
      // }),
      columnHelper.accessor((row) => row.email, {
        header: 'EMAIL',
        cell: (cell) => cell.getValue() || '-',
      }),
      // columnHelper.accessor((row) => row.position, {
      //   header: 'POSITION',
      //   cell: (cell) => cell.getValue() || '-',
      // }),
      // columnHelper.accessor((row) => row.locations, {
      //   header: 'LOCATION',
      //   cell: (cell) => (
      //     <div className="flex items-center justify-start gap-x-2">
      //       {cell.getValue().map((location) => (
      //         <LocationLabel
      //           key={location.id}
      //           name={location.name}
      //           onClick={() => onOpenLocationsModal(cell.row.index)}
      //         />
      //       ))}
      //     </div>
      //   ),
      // }),
    ],
    []
  );

  const pageCount = useMemo(() => {
    if (usersData?.users.length) {
      return Math.ceil(usersData.total / USERS_LIMIT);
    }

    return 1;
  }, [usersData?.total]);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDelayedSearch(search);
      setCurrentPage(1);
    }, 400);

    return () => clearTimeout(delayTimer);
  }, [search]);

  useEffect(() => {
    if (currentPage >= pageCount) {
      setCurrentPage(pageCount);
    }
  }, [pageCount]);

  const onOpenUserModal = (index?: number) => {
    if (typeof index === 'number') {
      setUserModalVariant('edit');
      setActiveRowIndex(index);
    } else {
      setUserModalVariant('add');
      setActiveRowIndex(-1);
    }

    setUserModalOpen(true);
  };

  const onDelete = (index: number) => {
    setActiveRowIndex(index);
    setDeleteModalOpen(true);
  };

  const onExportCV = async () => {
    try {
      const result = await exportCV(search).unwrap();

      downloadCSV(
        result,
        `${formatDate(new Date(), DateFormats.SHORT_FORMAT)}-users`
      );
    } catch (error) {
      showErrorToast();
    }
  };

  const refsArray = Array.from({ length: columns.length }, () =>
    useRef<HTMLButtonElement>(null)
  );

  const columnsNames = columns.map((col) => String(col.header));

  const addButton = (
    <Button variant="contained" onClick={onOpenUserModal}>
      Add user
    </Button>
  );

  return (
    <Page title="Manage Users" headContent={addButton}>
      <div className="flex flex-col l:flex-row l:justify-between">
        <SearchInput
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Type something"
        />

        <div className="w-full mt-8 l:w-fit flex-between l:mt-0">
          {!isDesktop && (
            <SortBy refsArray={refsArray} columns={columnsNames} />
          )}

          <Button
            onClick={onExportCV}
            loading={isCsvLoading}
            variant="outlined"
            disabled={!usersData?.users.length}
          >
            Export CSV
          </Button>
        </div>
      </div>

      {match(isLoading)
        .with(true, () => (
          <div className="flex-center h-[400px]">
            <Spinner remSize={2.5} />
          </div>
        ))
        .with(false, () =>
          usersData?.users ? (
            <>
              <LoaderContainer loading={isFetching}>
                <Table
                  withActionMenu
                  className="mt-4 l:mt-5"
                  columns={columns}
                  data={usersData.users}
                  onDelete={onDelete}
                  onEdit={onOpenUserModal}
                  headerRefs={refsArray}
                />
              </LoaderContainer>

              <Pagination
                pageSize={USERS_LIMIT}
                count={usersData.total}
                className="mx-auto mt-8 l:ml-auto l:mr-0"
                page={currentPage}
                setPage={setCurrentPage}
              />

              {/* {usersData.users[activeRowIndex] && (
                <LocationModal
                  isOpen={isLocationsModalOpen}
                  onClose={() => setLocationsModalOpen(false)}
                  selectedLocations={usersData.users[activeRowIndex].locations}
                  userId={usersData.users[activeRowIndex].id}
                />
              )}
              <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                id={usersData.users[activeRowIndex]?.id}
              /> */}
              <UserModal
                variant={userModalVariant}
                isOpen={isUserModalOpen}
                onClose={() => setUserModalOpen(false)}
                userDetails={usersData.users[activeRowIndex]}
              />
            </>
          ) : (
            <Text variant="h4" className="mt-4 l:mt-5">
              There is no data
            </Text>
          )
        )
        .exhaustive()}
    </Page>
  );
};
