import { BarChart, DateRangeFilter } from '@features';
import { Page, Spinner, Text } from '@ui';
import React, { FC } from 'react';
import { useBreakpoints } from '@hooks';
import { HourlySales, useGetHourlySalesReportQuery } from '@api';
import { match } from 'ts-pattern';
import { filtersSelectors } from '@store/entities';
import { useSelector } from '@store';

export const HourlySalesReport: FC = () => {
  const { isMobile } = useBreakpoints();

  const dateRangeFilters = useSelector(filtersSelectors.unixDateRangeFilter);
  const selectedLocations = useSelector(filtersSelectors.selectedLocations);

  const locationsIds = selectedLocations.map((location) => location.id);

  const {
    data: hourlySalesChartData,
    isLoading,
    isFetching,
  } = useGetHourlySalesReportQuery(
    {
      ...dateRangeFilters,
      locations: locationsIds,
    },
    {
      skip:
        !dateRangeFilters.startDate ||
        !dateRangeFilters.endDate ||
        !locationsIds.length,
    }
  );

  return (
    <Page
      title="Hourly Sales Report"
      wrapperClassName="!mt-8 l:!mt-[22px]"
      withIndicator
      positive
    >
      <div className="mb-6">
        <DateRangeFilter />
      </div>
      {match(isLoading || isFetching)
        .with(true, () => (
          <div className="flex-center h-[400px]">
            <Spinner remSize={2.5} />
          </div>
        ))
        .with(false, () =>
          hourlySalesChartData?.data ? (
            <BarChart<HourlySales>
              data={hourlySalesChartData?.data as unknown as HourlySales[]}
              xDataKey="time"
              domain={
                (hourlySalesChartData?.domains || []) as never as string[]
              }
              dimensions={{
                width: isMobile ? 600 : '100%',
                height: isMobile ? 456 : 464,
              }}
              YTickPrefix="$"
              stack
            />
          ) : (
            <Text variant="h4">There is no data</Text>
          )
        )
        .exhaustive()}
    </Page>
  );
};
