import { Spinner } from '@ui';
import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

interface LoaderContainerProps {
  loading: boolean;
  children: ReactNode;
  className?: string;
  spinnerClassName?: string;
}

export const LoaderContainer: FC<LoaderContainerProps> = ({
  loading,
  className,
  children,
  spinnerClassName,
}) => {
  return (
    <div className={cn('relative', className)}>
      <div className={cn({ 'opacity-40 pointer-events-none': loading })}>
        {children}
      </div>

      {loading && (
        <div className="absolute w-full text-center top-[40%]">
          <Spinner remSize={2} className={cn(spinnerClassName)} />
        </div>
      )}
    </div>
  );
};
