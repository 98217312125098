import numeral from 'numeral';

export const jsonCompare = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);

export const repeatArrayElements = <T>(arr: T[], targetLength: number): T[] =>
  Array.from({ length: targetLength }, (_, index) => arr[index % arr.length]);

export const formatNumber = (num: number, intFormat: string = '0,0') => {
  if (Number.isInteger(num)) {
    return numeral(num).format(intFormat);
  }

  return numeral(num).format('0,0.00');
};

export const firstCapitalLetter = (word: string) =>
  word.slice(0, 1).toUpperCase() + word.slice(1);

export const getUpdatedFields = <Object extends Record<string, any>>(
  obj: Object,
  newObj: Partial<Object>
) =>
  Object.entries(newObj)
    .filter(([key, value]) => !jsonCompare(obj[key], value))
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {} as Partial<Object>
    );

export const pluralize = (count: number, noun: string, suffix = 's') => {
  const correctSuffix = noun.endsWith('s') ? 'es' : suffix;

  return `${noun}${count !== 1 && count !== 0 ? correctSuffix : ''}`;
};
