import { Button, Icon, Input, SearchInput, Spinner, Text } from '@ui';
import { Icons, pluralize } from '@utils';
import { useClickAway } from '@hooks';
import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Location } from '@api';

interface SelectLocationsProps {
  locations: Location[];
  value: Location[];
  onChange: (value: Location[]) => void;
  className?: string;
  loading?: boolean;
}

const getInitialLocations = (): Location[] => {
  const token = localStorage.getItem('token');
  if (token) {
    const tokenData = JSON.parse(token);

    return tokenData.locations.sort((a: Location, b: Location) =>
      a.name.localeCompare(b.name)
    ) as Location[];
  }

  return [];
};

export const SelectLocations: FC<SelectLocationsProps> = ({
  locations = [],
  value,
  onChange,
  className,
  loading,
}) => {
  const [isShowLocations, setShowLocations] = useState(false);
  const [searchFilteredLocations, setSearchFilteredLocations] =
    useState(locations);
  const [selectedLocations, setSelectedLocations] = useState(value);
  const [search, setSearch] = useState('');

  const buttonRef = useRef(null);
  const ref = useClickAway(() => setShowLocations(false), [buttonRef]);

  // useEffect(() => {
  //   setSelectedLocations(value);
  // }, [value]);

  const onSelect = (location: Location) => {
    setSelectedLocations([...selectedLocations, location]);
  };

  const onRemove = (locationId: number) => {
    const filteredLocations = selectedLocations.filter(
      (location) => location.id !== locationId
    );

    setSelectedLocations(filteredLocations);
  };

  const isSelected = (locationId: number) =>
    selectedLocations?.some((location) => location.id === locationId);

  const onSubmit = () => {
    setShowLocations(false);
    onChange(selectedLocations);
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    const lowercaseValue = (e.target.value || '').toLowerCase();
    if (lowercaseValue) {
      const newFilteredLocations = locations?.filter((location) => {
        if (location.display_name.toLowerCase().includes(lowercaseValue)) {
          return location;
        }
        if (location.name.toLowerCase().includes(lowercaseValue)) {
          return location;
        }
        if (location.address_line_one.toLowerCase().includes(lowercaseValue)) {
          return location;
        }
        if (location.address_line_two.toLowerCase().includes(lowercaseValue)) {
          return location;
        }
        return false;
      });
      setSearchFilteredLocations(newFilteredLocations);
    } else {
      setSearchFilteredLocations(locations);
    }
  };
  return (
    <div className={cn('relative w-full', className)}>
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setShowLocations(!isShowLocations)}
        className={cn(
          'w-full min-w-[100px] h-8 py-3 px-2 flex-between l:justify-center gap-x-1 bg-grey-400 bg-opacity-20  border border-black-700 border-opacity-10 rounded-[5px] shadow-input-shadow'
        )}
      >
        {loading ? (
          <Spinner className="!text-black-700" remSize={0.5} />
        ) : (
          <>
            <Text variant="body2" className="!font-medium">
              Stores ({value?.length || 0})
            </Text>

            <Icon
              icon={Icons.ArrowDown}
              size={16}
              className={cn('transition-transform ml-auto', {
                'rotate-180': isShowLocations,
              })}
            />
          </>
        )}
      </button>

      {isShowLocations && !loading && locations?.length && (
        <div
          ref={ref}
          className="absolute left-0 z-20 bg-white rounded shadow-lg top-17 l:top-0"
        >
          <div className="p-3">
            <div className="flex items-center justify-between">
              <Text className="!font-semibold" variant="h5">
                Locations
              </Text>

              <button
                type="button"
                onClick={() => setShowLocations(false)}
                className="hover:opacity-80 text-black-700"
              >
                <Icon icon={Icons.Close} className="mb-1" size={12} />
              </button>
            </div>

            <div className="flex items-center justify-start mt-3 gap-x-3">
              <SearchInput
                value={search}
                onChange={onSearchInputChange}
                placeholder="Search location"
              />
            </div>

            <div className="flex items-center justify-start mt-3 gap-x-3">
              <Button
                variant="contained"
                className="!h-8"
                onClick={() => setSelectedLocations(locations)}
              >
                Select All
              </Button>

              <Button
                variant="outlined"
                className="!h-8"
                onClick={() => setSelectedLocations([])}
              >
                Clear
              </Button>
            </div>
          </div>

          <ul className="w-full py-1 overflow-y-auto bg-white max-h-52 l:min-w-fit custom-scroll">
            {searchFilteredLocations.map((location) => {
              const selected = isSelected(location.id);

              return (
                <li
                  key={location.id}
                  className={cn(
                    ' px-3 min-h-[30px] text-[12px] w-full text-left whitespace-nowrap cursor-pointer hover:bg-grey-450',
                    {
                      'font-bold': selected,
                    }
                  )}
                >
                  <button
                    type="button"
                    className="flex items-center justify-start w-full h-full py-1 text-left gap-x-2"
                    onClick={
                      selected
                        ? () => onRemove(location.id)
                        : () => onSelect(location)
                    }
                  >
                    <input
                      readOnly
                      type="checkbox"
                      checked={selected}
                      className="cursor-pointer"
                    />
                    <div className="flex-col items-center">
                      <p>{location.display_name}</p>
                      {location.address_line_one && (
                        <p className="text-gray-500">
                          {location.address_line_one}
                        </p>
                      )}
                      {location.address_line_two && (
                        <p className="text-gray-400">
                          {location.address_line_two}
                        </p>
                      )}
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>

          <div className="p-3">
            <Button
              className="!h-8 w-full"
              variant="contained"
              onClick={onSubmit}
              disabled={!selectedLocations.length}
            >
              {`Select ${selectedLocations.length} ${pluralize(
                selectedLocations.length,
                'locaton'
              )}`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
