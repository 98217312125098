import React, { useState } from 'react';
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import {
  CustomXAxisTick,
  CustomYAxisTick,
  CustomTooltip,
  Legend,
} from '../../Custom';
import './style.css';
import { chartColors, formatNumber } from '@utils';
import { useBreakpoints, useWindowSize } from '@hooks';
import { Dimensions } from '@types';

export interface BarChartProps<ChartElement> {
  data: ChartElement[];
  xDataKey: keyof ChartElement;
  domain: Array<keyof ChartElement>;
  dimensions: Dimensions;
  YTickPrefix?: string;
  stack?: boolean;
}

export const BarChart = <ChartElement extends object>({
  data,
  xDataKey,
  domain,
  dimensions,
  YTickPrefix,
  stack,
}: BarChartProps<ChartElement>) => {
  const { width } = useWindowSize();
  const { isDesktop } = useBreakpoints();

  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const getRadius = (index: number): [number, number, number, number] => {
    if (domain.length > 1) {
      return domain.length - 1 === index ? [6, 6, 0, 0] : [0, 0, 0, 0];
    }

    return [6, 6, 0, 0];
  };

  return (
    <div className="relative px-6 py-8 overflow-auto bg-white rounded-lg">
      <Legend domain={domain as string[]} />

      <ResponsiveContainer width={dimensions.width} height={dimensions.height}>
        <Chart data={data} barSize={30}>
          <CartesianGrid vertical={false} />

          <XAxis
            interval={width <= 1500 ? 1 : 0}
            dataKey={xDataKey as string}
            tick={<CustomXAxisTick />}
          />
          <YAxis
            tick={<CustomYAxisTick prefix={YTickPrefix} />}
            domain={[0, (dataMax: number) => dataMax * 1.1]}
          />

          <Tooltip
            content={<CustomTooltip domain={domain as string[]} />}
            cursor={{ fill: 'transparent' }}
            position={{ x: tooltipPosition.x - 25, y: tooltipPosition.y - 25 }}
            animationDuration={300}
          />

          {domain.map((group, index) => {
            const withLabelList =
              domain.length > 1 && domain.length - 1 === index;

            const fill = chartColors[index % chartColors.length];

            return (
              <Bar
                key={group.toString()}
                fill={fill}
                dataKey={group as string}
                onMouseEnter={setTooltipPosition}
                barSize={56}
                radius={getRadius(index)}
                stackId={stack ? 'a' : undefined}
              >
                {withLabelList && (
                  <LabelList
                    dataKey="total"
                    position="top"
                    formatter={(value: any) => {
                      return `$${formatNumber(Math.floor(value))}`;
                    }}
                    fill="#000"
                    fontSize={12}
                  />
                )}
              </Bar>
            );
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};
