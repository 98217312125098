import { Route, Routes } from '@navigation';
import { IconType, Icons } from '@utils';

export interface SidebarItem {
  icon: IconType;
  label: string;
  route: Route | null;
  children?: SidebarItem[];
}

export const sidebarItems: SidebarItem[] = [
  {
    icon: Icons.BarChart,
    label: 'Sales Dashboard',
    route: Routes.SALES_DASHBOARD,
  },
  {
    icon: Icons.Document,
    label: 'Reports',
    route: null,
    children: [
      {
        icon: Icons.Clock,
        label: 'Hourly Sales Report',
        route: Routes.HOURLY_SALES_REPORT,
      },
      // {
      //   icon: Icons.Dollar,
      //   label: 'Refund Report',
      //   route: Routes.REFUND_REPORT,
      // },
      {
        icon: Icons.Shape,
        label: 'Payment Types Report',
        route: Routes.PAYMENT_TYPES_REPORT,
      },
      {
        icon: Icons.Discount,
        label: 'Tax Report',
        route: Routes.TAX_REPORT,
      },
    ],
  },
  {
    icon: Icons.Clock,
    label: 'Staff Time Tracker',
    route: Routes.STAFF_TIME_TRACKER,
  },
];
