import { AnyAction, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { rootReducer } from './rootReducer';
import {
  authApi,
  locationsApi,
  refundsApi,
  reportsApi,
  usersApi,
  voidsApi,
} from '../api';
import { ordersApi } from '../api/orders/ordersApi';

const createStore = () => {
  const defaultMiddleware = [
    thunk,
    authApi.middleware,
    locationsApi.middleware,
    ordersApi.middleware,
    refundsApi.middleware,
    voidsApi.middleware,
    usersApi.middleware,
    reportsApi.middleware,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: defaultMiddleware,
  });

  return store;
};

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof rootReducer>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  AnyAction
>;

export const useDispatch = () => useReduxDispatch<TypedDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector;
