export const getRangePages = (currentPage: number, pageCount: number) => {
  if (pageCount < 1) {
    return [1];
  }

  const range = 1;
  const pages = [];

  for (
    let i = Math.max(1, currentPage - range);
    i <= Math.min(pageCount, currentPage + range);
    i++
  ) {
    pages.push(i);
  }

  const paginationArray: number[] = [];

  if (currentPage > 1) {
    paginationArray.push(1);

    if (currentPage > 2) {
      paginationArray.push(-1);
    }
  }

  paginationArray.push(
    ...pages.filter((page) => !paginationArray.includes(page))
  );

  if (currentPage < pageCount && !paginationArray.includes(pageCount)) {
    if (currentPage < pageCount - 1) {
      paginationArray.push(-1);
    }

    paginationArray.push(pageCount);
  }

  return paginationArray;
};
