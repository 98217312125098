import { createSlice } from '@reduxjs/toolkit';

import { MenuSlice } from './types';

const initialState: MenuSlice = {
  isOpen: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isOpen = true;
    },
    closeMenu: (state) => {
      state.isOpen = false;
    },
  },
});

export const menuActions = menuSlice.actions;
