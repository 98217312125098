import { firstCapitalLetter, formatNumber } from '@utils';
import { Page, SortBy, Spinner, Table, Text } from '@ui';
import React, { FC, useMemo, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DateRangeFilter, PieChart } from '@features';
import { useBreakpoints } from '@hooks';
import { PaymentTypesElement, useGetPaymentTypesReportQuery } from '@api';
import { match } from 'ts-pattern';
import { useSelector } from '@store';
import { filtersSelectors } from '@store/entities';
import cn from 'classnames';

export const PaymentTypesReport: FC = () => {
  const { isDesktop } = useBreakpoints();

  const dateRangeFilters = useSelector(filtersSelectors.unixDateRangeFilter);
  const selectedLocations = useSelector(filtersSelectors.selectedLocations);

  const locationsIds = selectedLocations.map((location) => location.id);

  const columnHelper = createColumnHelper<PaymentTypesElement>();

  const { data: paymentTypesData, isLoading } = useGetPaymentTypesReportQuery({
    ...dateRangeFilters,
    locations: locationsIds,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.type, {
        header: 'PAYMENT TYPE',
        cell: (cell) => firstCapitalLetter(cell.getValue()) || '-',
      }),
      columnHelper.accessor((row) => row.total_amount, {
        header: 'TOTAL',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
    ],
    []
  );

  const refsArray = Array.from({ length: columns.length }, () =>
    useRef<HTMLButtonElement>(null)
  );

  const columnsNames = columns.map((col) => String(col.header));

  return (
    <Page title="Payment Types Report" withIndicator positive>
      {match(isLoading)
        .with(true, () => (
          <div className="flex-center h-[400px]">
            <Spinner remSize={2.5} />
          </div>
        ))
        .with(false, () =>
          paymentTypesData ? (
            <div className="flex flex-col justify-between l:flex-row-reverse gap-x-12 gap-y-8">
              {!!paymentTypesData.length && (
                <div className="flex-grow l:w-[50%] 3xl:w-[40%] l:mt-14 h-[400px] l:h-auto">
                  <PieChart<PaymentTypesElement>
                    data={paymentTypesData}
                    dataKey="total_amount"
                    nameKey="type"
                    dimensions={{
                      width: '100%',
                      height: '80%',
                    }}
                  />
                </div>
              )}

              <div
                className={cn(
                  'l:w-[50%] 3xl:w-[60%] l:min-w-[50%] flex-wrap gap-x-4',
                  {
                    'l:!w-full': !paymentTypesData.length,
                  }
                )}
              >
                <DateRangeFilter />

                {!isDesktop && (
                  <SortBy
                    className="mt-10 mb-6"
                    refsArray={refsArray}
                    columns={columnsNames}
                  />
                )}

                <Table
                  className="mt-4 l:mt-6 l:min-w-[280px]"
                  data={paymentTypesData}
                  columns={columns}
                  headerRefs={refsArray}
                />
              </div>
            </div>
          ) : (
            <Text variant="h4">There is no data</Text>
          )
        )
        .exhaustive()}
    </Page>
  );
};
