import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { Routes } from '../navigation';
import qs from 'qs';

const initialQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'brackets'}),
});

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const baseQuery: BaseQuery = async (args, api, extraOptions) => {
  const result = await initialQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    window.location.pathname = Routes.LOGIN;
  }

  if (result.error?.status === 403) {
    window.location.pathname = Routes.SALES_DASHBOARD;
  }

  return result;
};

const mockedBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_MOCKED_API_URL,
});

export { baseQuery, mockedBaseQuery };
