export type Platform = 'Website' | 'Mobile App';

export enum RefundReason {
  WRONG_ITEM = 'Wrong item received',
  DELIVERY_LATE = 'Late delivery',
  BILLING_ERROR = ' Billing error',
  WRONG_SIZE = 'Ordered wrong size',
}

export type Position =
  | 'Manager'
  | 'Designer'
  | 'Supervisor'
  | 'Cashier'
  | 'Sales Associate'
  | 'Stock Clerk';
