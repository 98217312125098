import React from 'react';
import cn from 'classnames';
import {
  CaptionProps,
  DayContentProps,
  useNavigation,
  DayContent as PickerDayContent,
} from 'react-day-picker';
import { Icons, formatDate, DateFormats } from '@utils';

import { Icon } from '../Icon';

export const Caption = ({ displayMonth }: CaptionProps) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  const [month, year] = formatDate(displayMonth, DateFormats.MONTH_YEAR).split(
    ' '
  );

  return (
    <div className="flex flex-row items-center justify-between w-full mb-2">
      <button
        type="button"
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className="hover:opacity-70"
      >
        <Icon
          icon={Icons.ArrowDown}
          size={24}
          className="text-blue-400 rotate-90"
        />
      </button>

      <span className="text-base translate-y-px l:text-xs">
        <b className="font-medium">{month}</b>
        {' '}
        {year}
      </span>

      <button
        type="button"
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className="hover:opacity-70"
      >
        <Icon
          icon={Icons.ArrowDown}
          size={24}
          className="text-blue-400 -rotate-90"
        />
      </button>
    </div>
  );
};

export const DayContent = (props: DayContentProps) => {
  const { activeModifiers } = props;

  const isUnselectedDay =
    !!activeModifiers.today &&
    !(
      !!activeModifiers.range_start ||
      !!activeModifiers.range_middle ||
      !!activeModifiers.range_end
    ) &&
    !activeModifiers.selected;

  return (
    <div
      className={cn('font-normal !text-[12px]', {
        'text-blue-400': isUnselectedDay,
      })}
    >
      <PickerDayContent {...props} />
    </div>
  );
};
