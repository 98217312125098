import React, { FC, useRef, useState } from 'react';
import { Text } from '../../Text';
import { Icon } from '../../Icon';
import {
  DateFormats,
  Icons,
  formatDate,
  formatTime,
  getHoursFromTime,
} from '@utils';
import { useBreakpoints, useClickAway } from '@hooks';
import cn from 'classnames';
import { DayPicker } from 'react-day-picker';

import { Caption, DayContent } from '../Custom';
import { Time } from '@types';
import { TimePicker } from '../TimePicker';
import { Button } from '../../Button';
import { Label } from '../../Label';
import { PickerContainer } from '../PickerContainer';
import dayjs from 'dayjs';

const today = new Date();

interface DayTimePickerProps {
  value: Date;
  label?: string;
  position?: 'left' | 'right';
  disabledBefore?: Date;
  disabledAfter?: Date;
  onChange: (value: Date) => void;
}

export const DayTimePicker: FC<DayTimePickerProps> = ({
  value,
  label,
  position = 'right',
  disabledAfter,
  disabledBefore,
  onChange,
}) => {
  const { isDesktop } = useBreakpoints();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isPickerVisible, setPickerVisible] = useState(false);
  const [date, setDate] = useState<Date | undefined>(value);
  const [time, setTime] = useState<Time>({
    isAMSelected: true,
    selectedHour: '06:00',
  });

  const ref = useClickAway(() => setPickerVisible(false), [buttonRef]);

  const selectedDateFormatted = formatDate(
    value || new Date(),
    DateFormats.AMERICAN_FORMAT
  );

  const selectedTimeFormatted = formatTime(value);
  const { isAMSelected, selectedHour } = selectedTimeFormatted;

  const getDateWithHours = () =>
    new Date(date || today).setHours(getHoursFromTime(time), 0, 0);

  const onSubmit = () => {
    setPickerVisible(false);
    onChange(new Date(getDateWithHours()));
  };

  return (
    <div className="relative w-full l:w-min">
      {label && <Label text={label} className="mb-2 l:mb-1" />}

      <button
        ref={buttonRef}
        type="button"
        onClick={() => setPickerVisible(!isPickerVisible)}
        className={cn(
          'relative w-full l:w-min h-12 l:h-8 bg-white border border-borderColor rounded-[5px] whitespace-nowrap shadow-input-shadow',
          {
            '!border-blue-400': isPickerVisible,
          }
        )}
      >
        <div className="flex flex-row items-center justify-between px-3 ">
          <div className="flex justify-between items-center gap-x-1.5">
            <div>
              <Icon
                icon={Icons.Calendar}
                size={isDesktop ? 16 : 24}
                className="text-grey-600"
              />
            </div>

            <Text variant={isDesktop ? 'body2' : 'body1'} className="mt-0.5">
              {`${selectedDateFormatted} ${selectedHour} ${
                isAMSelected ? 'am' : 'pm'
              }`}
            </Text>
          </div>
        </div>
      </button>

      {isPickerVisible && (
        <PickerContainer
          isOpen={isPickerVisible}
          onClose={() => setPickerVisible(false)}
        >
          <div
            ref={isDesktop ? ref : null}
            className={cn(
              'l:absolute z-30 top-0 l:top-[46px]  px-4 l:px-6 pt-8 pb-5 l:py-4 flex flex-col l:flex-row justify-start items-stretch gap-x-6 rounded-[10px] bg-white shadow-md',
              {
                'top-[20px] l:top-[66px]': !!label,
                'left-0': position === 'right',
                'right-0': position === 'left',
              }
            )}
          >
            <DayPicker
              mode="single"
              weekStartsOn={1}
              onSelect={setDate}
              selected={date}
              components={{ Caption, DayContent }}
              defaultMonth={today}
              disabled={{
                before: disabledBefore || undefined,
                after: disabledAfter || today,
              }}
              formatters={{
                formatWeekdayName: (dayDate) => dayjs(dayDate).format('ddd'),
              }}
              styles={{
                head_cell: {
                  width: '60px',
                  textTransform: 'uppercase',
                  fontSize: isDesktop ? '10px' : '12px',
                  color: '#D6D6D6',
                  fontWeight: 400,
                },
                table: {
                  maxWidth: 'none',
                },
                day: {
                  margin: 'auto',
                },
              }}
            />
            <div className="h-full mt-4 l:mt-0">
              <div className="flex flex-col justify-between">
                <span className="mb-3 text-base font-semibold l:text-sm l:mb-4">
                  Select Time
                </span>

                <TimePicker label="TIME" value={time} onChange={setTime} />
              </div>

              <Button
                variant="contained"
                onClick={onSubmit}
                className="w-full !h-10 !text-xs l:w-auto px-4 ml-auto mt-8 l:mt-[97px] l:!h-8"
              >
                Submit
              </Button>
            </div>
          </div>
        </PickerContainer>
      )}
    </div>
  );
};
