export const downloadCSV = (csv: string, fileName: string) => {
  console.log('CSV', csv);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.href = url;
  a.download = `${fileName}.csv`;
  a.click();

  URL.revokeObjectURL(url);
};

export const downloadCSVFromURL = (csvURL: string, fileName: string) => {
  const a = document.createElement('a');

  a.href = csvURL;
  a.download = `${fileName}.csv`;
  a.target = '_blank';
  a.click();
};
