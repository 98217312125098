import React, { FC, InputHTMLAttributes } from 'react';
import { Icon } from '../Icon';
import { Icons } from '@utils';
import { Label } from '../Label';
import cn from 'classnames';

import './searchInput.css';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value: string;
}

export const SearchInput: FC<SearchInputProps> = ({
  label,
  value,
  ...rest
}) => {
  return (
    <div className="relative">
      {label && <Label text={label} className="mb-2 l:mb-1" />}

      <input
        type="search"
        className="h-12 l:h-8 pl-9 pr-3 w-full l:w-[220px] flex items-center px-3 text-[12px] border border-grey-400 placeholder:text-grey-650 focus:border-blue-400  outline-none rounded-[5px] overflow-hidden text-ellipsis"
        {...rest}
        value={value}
      />

      <Icon
        icon={Icons.Search}
        size={16}
        className={cn('absolute text-grey-650 left-3 top-4 l:top-2', {
          '!top-[38px] l:!top-[26px]': label,
        })}
      />
    </div>
  );
};
