import { AllOrNothing } from '@types';
import { Row, flexRender } from '@tanstack/react-table';
import React from 'react';
import { ActionMenu } from '@features';
import cn from 'classnames';

type TableBodyProps<Data> = {
  rows: Row<Data>[];
  tdClassName?: string;
  rowClassName?: string;
  onClick: (index: number) => void;
  noRowNumberColumn: boolean;
} & AllOrNothing<{
  withActionMenu: boolean;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}>;

export const TableBody = <Data extends object>({
  rows,
  tdClassName,
  rowClassName,
  withActionMenu,
  onClick,
  onEdit,
  onDelete,
  noRowNumberColumn,
}: TableBodyProps<Data>) =>
  rows.map((row, rowIndex) => {
    const isLastRow = rowIndex === rows.length - 1;

    return (
      <tr
        onClick={() => onClick(row.index)}
        key={row.id}
        className={rowClassName}
      >
        {!noRowNumberColumn && (
          <td
            className={cn(
              'sticky left-0 w-[38px] h-[51px] l:h-[56px] bg-inherit !p-0',
              tdClassName
            )}
          >
            <div className="w-full h-full td-shadow px-[14px] flex-center td-shadow l:!shadow-none ">
              {rowIndex + 1}
            </div>
          </td>
        )}

        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} className={tdClassName}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}

        {withActionMenu && (
          <td>
            <ActionMenu
              position={isLastRow ? 'top' : 'bottom'}
              onEdit={() => onEdit(row.index as number)}
              onDelete={() => onDelete(row.index as number)}
            />
          </td>
        )}
      </tr>
    );
  });
