import { useBreakpoints, useToast } from '@hooks';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  LoaderContainer,
  Page,
  SortBy,
  Spinner,
  Table,
  Text,
} from '@ui';
import React, { FC, useMemo, useRef } from 'react';
import { DateFormats, downloadCSV, formatDate, formatNumber } from '@utils';
import { DateRangeFilter } from '@features';
import {
  TaxReportResponse,
  useGetTaxReportCSVMutation,
  useGetTaxReportQuery,
} from '@api';
import { match } from 'ts-pattern';
import { useSelector } from '@store';
import { filtersSelectors } from '@store/entities';

export const TaxReport: FC = () => {
  const { showErrorToast } = useToast();
  const { isDesktop } = useBreakpoints();
  const columnHelper = createColumnHelper<TaxReportResponse>();

  const dateRangeFilters = useSelector(filtersSelectors.unixDateRangeFilter);
  const selectedLocations = useSelector(filtersSelectors.selectedLocations);

  const locationsIds = selectedLocations.map((location) => location.id);

  const [exportCV, { isLoading: isCsvLoading }] = useGetTaxReportCSVMutation();

  const {
    data: taxReportData,
    isLoading,
    isFetching,
  } = useGetTaxReportQuery({ ...dateRangeFilters, locations: locationsIds });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        header: 'TAX CODE',
        cell: (cell) => cell.getValue() || '-',
      }),
      columnHelper.accessor((row) => row.amount, {
        header: 'TAX TOTAL',
        cell: (cell) => `$${formatNumber(cell.getValue())}` || '-',
      }),
    ],
    []
  );

  const refsArray = Array.from({ length: columns.length }, () =>
    useRef<HTMLButtonElement>(null)
  );

  const columnsNames = columns.map((col) => String(col.header));

  const onExportCV = async () => {
    try {
      if (taxReportData) {
        const result = await exportCV({
          ...dateRangeFilters,
          locations: locationsIds,
        }).unwrap();

        downloadCSV(
          result,
          `${formatDate(new Date(), DateFormats.SHORT_FORMAT)}-tax-report`
        );
      }
    } catch (error) {
      showErrorToast();
    }
  };

  return (
    <Page title="Tax Report" withIndicator positive>
      <div className="flex flex-col flex-wrap items-center justify-between gap-y-8 gap-x-4 l:flex-row l:mt-10">
        <DateRangeFilter />

        <div className="w-full l:w-fit flex-between">
          {!isDesktop && (
            <SortBy refsArray={refsArray} columns={columnsNames} />
          )}

          {/* <Button
            onClick={onExportCV}
            loading={isCsvLoading}
            variant="outlined"
            disabled={!taxReportData}
          >
            Export CV
          </Button> */}
        </div>
      </div>

      {match(isLoading)
        .with(true, () => (
          <div className="flex-center h-[400px]">
            <Spinner remSize={2.5} />
          </div>
        ))
        .with(false, () =>
          taxReportData ? (
            <LoaderContainer loading={isFetching}>
              <Table
                className="mt-4 l:mt-6"
                columns={columns}
                data={[taxReportData]}
                headerRefs={refsArray}
              />
            </LoaderContainer>
          ) : (
            <Text variant="h4" className="mt-4 l:mt-6">
              There is no data
            </Text>
          )
        )
        .exhaustive()}
    </Page>
  );
};
