import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FiltersSlice } from './types';
import { today } from '@utils';
import { DateRange, DateRangeOption } from '@types';
import { Location, locationsApi } from '@api';
import dayjs from 'dayjs';

const startOyDay = dayjs().tz().startOf('day').toDate();

const initialState: FiltersSlice = {
  dateRangeFilter: {
    from: startOyDay,
    to: today,
  },

  selectedDateRange: 'today',
  selectedLocations: [],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<DateRange>) => {
      state.dateRangeFilter = payload;
    },
    setDateRange: (state, { payload }: PayloadAction<DateRangeOption>) => {
      state.selectedDateRange = payload;
    },
    setSelectedLocations: (state, { payload }: PayloadAction<Location[]>) => {
      state.selectedLocations = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      locationsApi.endpoints.getLocations.matchFulfilled,
      (state, { payload }) => {
        state.selectedLocations = payload;
      }
    );
  },
});

export const filtersActions = filtersSlice.actions;
