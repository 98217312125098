import { Accordion, Icon } from '@ui';
import { Icons, Images } from '@utils';
import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarItem } from './SidebarItem';
import { SidebarItem as ISidebarItem, sidebarItems } from './config';
import { useBreakpoints } from '@hooks';
import { Route, Routes, hasRouteAccess } from '@navigation';
import { useDispatch, useSelector } from '@store';
import {
  filtersActions,
  filtersSelectors,
  menuActions,
  userActions,
  userSelectors,
} from '@store/entities';
import { Location, useLogoutMutation } from '@api';
import { SelectLocations } from '@features';

const getInitialLocations = (): Location[] => {
  const token = localStorage.getItem('token');
  if (token) {
    const tokenData = JSON.parse(token);

    return tokenData.locations.sort((a: Location, b: Location) =>
      a.name.localeCompare(b.name)
    ) as Location[];
  }

  return [];
};

export const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const [isHiddenSidebar, setIsHiddenSidebar] = useState(false);
  const { pathname } = useLocation();
  const { isDesktop } = useBreakpoints();

  // const isAdmin = useSelector(userSelectors.isAdmin);

  const user = useSelector(userSelectors.user);

  const selectedLocations = useSelector(filtersSelectors.selectedLocations);
  const [initialLocations, setInitialLocations] = useState(
    getInitialLocations()
  );

  const [logout] = useLogoutMutation();

  const onChangeLocations = (newValue: Location[]) => {
    dispatch(filtersActions.setSelectedLocations(newValue));
  };

  const renderMenuItems = (
    items: ISidebarItem[],
    nested?: boolean
  ): ReactNode[] =>
    items.map((item) => {
      if (
        item.route &&
        !hasRouteAccess(item.route as Route, user?.email as string)
      ) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
      }

      const isActive = pathname.includes(item.route as string);

      const trigger = (
        <SidebarItem
          {...item}
          isActive={isActive}
          className="hover:!bg-transparent"
        />
      );

      if (item.children) {
        if (
          item.label === 'Reports' &&
          !hasRouteAccess(Routes.REPORTS, user?.email as string)
        ) {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }
        return (
          <Accordion
            key={item.label}
            trigger={trigger}
            headerTitle={item.label}
            headerIcon={item.icon}
          >
            <div className="relative ml-8 pl-3 pr-6 l:ml-[18px] l:pl-4 l:pr-0">
              <div className="h-[calc(100%-40px)] absolute top-0 -left-[0.5px] l:left-[3px] w-px bg-black-700" />

              {renderMenuItems(item.children, true)}
            </div>
          </Accordion>
        );
      }

      return (
        <SidebarItem
          key={item.label}
          isNested={nested}
          {...item}
          isActive={isActive}
        />
      );
    });

  const isManageUsersActive = pathname.includes(Routes.MANAGE_USERS);

  const onLogout = () => {
    logout();

    dispatch(userActions.logout());
    dispatch(menuActions.closeMenu());

    window.location.pathname = Routes.LOGIN;
    localStorage.removeItem('token');
  };

  const onClickHideSidebar = () => {
    dispatch(menuActions.closeMenu());
    setIsHiddenSidebar(!isHiddenSidebar);
  };

  return (
    <nav
      className={`flex flex-col justify-between items-start h-full bg-white py-10  ${
        isHiddenSidebar ? 'w-[44px]' : ' w-[270px] l:px-6'
      } transition-all duration-300`}
    >
      <div className="w-full">
        <div className="flex items-center justify-start gap-x-4">
          {isDesktop && !isHiddenSidebar && (
            <>
              <img
                src={Images.AmigoLogo}
                alt="amigo-logo"
                className="w-[82px] h-[27px]"
              />

              <div className="w-px h-6 bg-black-700 opacity-20" />

              <img
                src={Images.Burrito}
                alt="amigo-logo"
                className="h-8 w-[58px]"
              />
            </>
          )}

          <button
            onClick={onClickHideSidebar}
            type="button"
            className="flex justify-start items-center gap-x-1.5 py-2.5 px-[22px] l:px-2.5 hover:opacity-70 active:brightness-90 mt-2.5 l:mt-0"
          >
            <Icon color="#2454DE" icon={Icons.Hamburger} size={24} />
          </button>
        </div>

        {isDesktop && !isHiddenSidebar && (
          <>
            {initialLocations.length > 1 ? (
              <SelectLocations
                value={selectedLocations}
                onChange={onChangeLocations}
                locations={initialLocations}
                className="l:mt-10"
              />
            ) : (
              <div className="flex items-center justify-start gap-x-4 l:mt-10">
                <div className="flex-col items-center">
                  <p>{initialLocations?.[0]?.display_name}</p>
                  {initialLocations?.[0]?.address_line_one && (
                    <p className="text-gray-500">
                      {initialLocations?.[0]?.address_line_one}
                    </p>
                  )}
                  {initialLocations?.[0]?.address_line_two && (
                    <p className="text-gray-400">
                      {initialLocations?.[0]?.address_line_two}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="w-full h-px bg-[#000] bg-opacity-10 my-6" />
          </>
        )}

        {!isHiddenSidebar && (
          <div className="w-full">{renderMenuItems(sidebarItems)}</div>
        )}
      </div>

      <div className="w-full">
        {/* {isAdmin && (
          <SidebarItem
            route={Routes.MANAGE_USERS}
            icon={Icons.Settings}
            label="Manage Users"
            isActive={isManageUsersActive}
          />
        )} */}

        <button
          onClick={onLogout}
          type="button"
          className="flex justify-start items-center text-red-100 gap-x-1.5 py-2.5 px-[22px] l:px-2.5 hover:opacity-70 active:brightness-90 mt-2.5 l:mt-0"
        >
          <Icon icon={Icons.Exit} size={24} />
          {!isHiddenSidebar && (
            <span className="inline-block w-16 text-base font-medium">
              Log Out
            </span>
          )}
        </button>
      </div>
    </nav>
  );
};
