import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { match } from 'ts-pattern';

import { TextVariant } from './config';

interface TextProps {
  variant: TextVariant;
  children: ReactNode;
  className?: string;
}

export const Text: FC<TextProps> = ({ variant, className, children }) =>
  match(variant)
    .with('h1', () => (
      <h1 className={cn('font-normal text-[48px] leading-[57.6px]', className)}>
        {children}
      </h1>
    ))
    .with('h2', () => (
      <h1 className={cn('font-normal text-[40px] leading-[48px]', className)}>
        {children}
      </h1>
    ))
    .with('h3', () => (
      <h3 className={cn('font-normal text-[28px] leading-[33.6px]', className)}>
        {children}
      </h3>
    ))
    .with('h4', () => (
      <h4 className={cn('font-normal text-[20px] leading-[24px]', className)}>
        {children}
      </h4>
    ))
    .with('h5', () => (
      <h5 className={cn('font-normal text-[18px] leading-[27px]', className)}>
        {children}
      </h5>
    ))
    .with('body1', () => (
      <p className={cn('font-normal text-sm', className)}>{children}</p>
    ))
    .with('body2', () => (
      <p className={cn('font-normal text-[12px] leading-[14.4px]', className)}>
        {children}
      </p>
    ))
    .exhaustive();
