import React, { RefObject } from 'react';
import {
  TableOptions,
  defaultColumnSizing,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import './table.css';

import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import cn from 'classnames';
import { AllOrNothing } from '@types';
import { useBreakpoints } from '@hooks';

interface AdditionalTableProps {
  className?: string;
  headerRefs: RefObject<HTMLButtonElement>[];
  noRowNumberColumn?: boolean;
}

type TableProps<Data> = Pick<TableOptions<Data>, 'data' | 'columns'> &
  AdditionalTableProps &
  AllOrNothing<{
    withActionMenu: boolean;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
  }> &
  AllOrNothing<{
    clickableRow: boolean;
    onClickRow?: (index: number) => void;
  }>;

export const Table = <Data extends object>({
  data,
  columns,
  className,
  withActionMenu,
  clickableRow,
  headerRefs,
  onDelete,
  onEdit,
  onClickRow = () => {},
  noRowNumberColumn = false,
}: TableProps<Data>) => {
  const { isDesktop } = useBreakpoints();

  const table = useReactTable({
    data,
    columns,
    defaultColumn: { ...defaultColumnSizing },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // console.log('data', data);

  return (
    <div className={cn('flex', className)}>
      <div className="w-full overflow-x-auto custom-scroll">
        <table className="page-table">
          <thead>
            <TableHead
              headerGroups={table.getHeaderGroups()}
              withActionMenu={withActionMenu}
              hideSort={!isDesktop}
              refs={headerRefs}
              noRowNumberColumn={noRowNumberColumn}
            />
          </thead>

          {data.length ? (
            <tbody>
              {withActionMenu ? (
                <TableBody
                  rows={table.getRowModel().rows}
                  withActionMenu={withActionMenu}
                  rowClassName=""
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onClick={clickableRow ? onClickRow : () => {}}
                  noRowNumberColumn={noRowNumberColumn}
                />
              ) : (
                <TableBody
                  rows={table.getRowModel().rows}
                  onClick={clickableRow ? onClickRow : () => {}}
                  rowClassName={cn('', {
                    'cursor-pointer': clickableRow,
                  })}
                  noRowNumberColumn={noRowNumberColumn}
                />
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={columns.length + 2} className="text-center">
                  There is no data
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
