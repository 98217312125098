import cn from 'classnames';
import React, { FC, useState, useEffect, forwardRef, Ref } from 'react';
import { Sidebar } from '../Sidebar';

export interface MobileSidebarProps {
  isOpen: boolean;
  ref: Ref<any>;
}

export const MobileSidebar: FC<MobileSidebarProps> = forwardRef(
  ({ isOpen }, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
      let animationTimer: NodeJS.Timeout;

      if (isOpen) {
        animationTimer = setTimeout(() => setIsAnimating(true), 0);
      } else {
        animationTimer = setTimeout(() => setIsAnimating(false), 300);
      }

      return () => {
        clearTimeout(animationTimer);
      };
    }, [isOpen]);

    return !isAnimating && !isOpen ? null : (
      <div className="fixed inset-0 z-20 overflow-hidden transition-all duration-300 ease-in-out">
        <div className="fixed inset-0 transition-all duration-300 ease-in-out transform top-16">
          <div
            className={cn('absolute inset-0 ', {
              'backdrop-blur-sm': isOpen,
            })}
          />
        </div>

        <div
          ref={ref}
          className={cn(
            'fixed pt-16 inset-y-0 right-0 max-w-full flex outline-none bg-white transform transition-transform ease-in-out duration-300',
            {
              'translate-x-0': isOpen && isAnimating,
              'translate-x-full': !isOpen || !isAnimating,
            }
          )}
        >
          <div className="w-fit">
            <div
              className={cn('relative flex flex-col h-full bg-white shadow-xl')}
            >
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
