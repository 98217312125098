import { useBreakpoints, useToast } from '@hooks';
import { Button, Input, Text } from '@ui';
import { Images } from '@utils';
import cn from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import React, { FC } from 'react';
import { LoginSchema, LoginValues } from './helpers/validation';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '@api';

export const Login: FC = () => {
  const { showErrorToast } = useToast();
  const { isDesktop } = useBreakpoints();
  const navigate = useNavigate();

  const [login] = useLoginMutation();

  const form = useFormik<LoginValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: toFormikValidationSchema(LoginSchema),
    onSubmit: async ({ email, password }) => {
      try {
        await login({ email, password }).unwrap();

        if (email === 'hours@amigocloud.ca') {
          navigate('/staff-time-tracker');
        } else {
          navigate('/sales-dashboard');
        }
      } catch (error) {
        showErrorToast();
      }
    },
  });

  const { handleSubmit, isSubmitting, isValid } = form;

  return (
    <FormikProvider value={form}>
      <form onSubmit={handleSubmit}>
        <main className="flex flex-col h-screen l:flex-row-reverse">
          <img
            src={isDesktop ? Images.DishesDesktop : Images.DishesMobile}
            alt="dishes"
            className="h-[333px] object-cover w-full l:w-[55%] l:h-full"
          />

          <section className="l:flex items-center  px-6 pt-8 pb-[83px] l:pt-10 l:pl-20 l:pr-[110px] w-full">
            <div className="flex flex-col w-full max-w-lg mx-auto gap-y-6">
              <div className="flex items-center justify-start h-8 mr-auto gap-x-4 l:gap-x-6 l:absolute l:top-10">
                <img
                  src={Images.AmigoLogo}
                  alt="amigo-logo"
                  className="w-[82px] h-[27px] l:w-[123px] l:h-[41px]"
                />

                <div className="w-px h-6 bg-[#252525] opacity-20" />

                <img
                  src={Images.Burrito}
                  alt="amigo-logo"
                  className="h-8 w-[58px] l:w-[90px] l:h-12"
                />
              </div>

              <Text
                variant="h1"
                className={cn('!font-semibold mt-8', {
                  '!text-[32px] !leading-[44px]': !isDesktop,
                })}
              >
                Log in
              </Text>

              <Input<LoginValues>
                fieldName="email"
                label="Email"
                placeholder="Email"
                inputClassName="h-12 !text-base"
                dynamicLabel
              />

              <div className="flex flex-col items-end">
                <Input<LoginValues>
                  fieldName="password"
                  label="Password"
                  className="w-full"
                  placeholder="Password"
                  type="password"
                  inputClassName="h-12 !text-base"
                  dynamicLabel
                />

                {/*
                <button
                  type="button"
                  className="self-end mt-2 hover:brightness-110 active:brightness-75"
                >
                  <Text variant="body2" className="!font-medium text-blue-400">
                    {isDesktop
                      ? 'Forgot password?'
                      : 'Forgotten your password?'}
                  </Text>
                </button>
                */}
              </div>

              <Button
                variant="contained"
                type="submit"
                className="mt-2 l:mt-0 !h-12 w-full !text-lg"
                disabled={!isValid}
                loading={isSubmitting}
              >
                Log in
              </Button>
            </div>
          </section>
        </main>
      </form>
    </FormikProvider>
  );
};
