import { HeaderGroup, flexRender } from '@tanstack/react-table';
import React, { RefObject } from 'react';
import { Icon } from '../../Icon';
import { Icons } from '@utils';
import cn from 'classnames';

interface TableHeadProps<Data> {
  headerGroups: HeaderGroup<Data>[];
  thClassName?: string;
  hideSort?: boolean;
  withActionMenu?: boolean;
  refs?: RefObject<HTMLButtonElement>[];
  noRowNumberColumn: boolean;
}

export const TableHead = <Data extends object>({
  headerGroups,
  thClassName,
  hideSort,
  withActionMenu,
  refs,
  noRowNumberColumn,
}: TableHeadProps<Data>) =>
  headerGroups.map((group) => (
    <tr key={group.id}>
      {!noRowNumberColumn && (
        <th
          className={cn(
            'sticky left-0 h-12 bg-inherit z-10 w-[38px] !p-0',
            thClassName
          )}
        >
          <div className="w-full h-full td-shadow l:shadow-none px-[14px] flex-center">
            #
          </div>
        </th>
      )}

      {group.headers.map((header, index) => {
        const sort = header.column.getIsSorted();

        return (
          <th key={header.id} className={cn(thClassName)}>
            <button
              ref={refs?.length ? refs[index] : null}
              className={cn('flex items-center justify-center', {
                'text-black-700': sort !== false,
              })}
              type="button"
              {...{
                onClick: header.column.getToggleSortingHandler(),
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}

              {!hideSort && (
                <Icon
                  icon={Icons.ArrowRight}
                  color={sort !== false ? '#131313' : '#9599A0'}
                  className={cn('rotate-90', {
                    '!rotate-90': sort === 'asc',
                    '!-rotate-90': sort === 'desc',
                  })}
                  size={16}
                />
              )}
            </button>
          </th>
        );
      })}

      {withActionMenu && <th aria-label="action" />}
    </tr>
  ));
