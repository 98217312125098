/* eslint-disable quote-props */

import React, { InputHTMLAttributes, useState } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import './style.css';
import { Label } from '../Label';
import { Icon } from '../Icon';
import { Icons } from '@utils';

interface InputProps<Form> extends InputHTMLAttributes<HTMLInputElement> {
  fieldName?: keyof Form;
  label?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  dynamicLabel?: boolean;
}

export const Input = <Form extends object>({
  fieldName,
  label,
  inputClassName,
  wrapperClassName,
  labelClassName,
  dynamicLabel,
  ...rest
}: InputProps<Form>) => {
  const [{ value, onChange, onBlur }, { error, touched }] = useField({
    name: fieldName as string,
  });

  const [isPasswordShow, setPasswordShow] = useState(false);

  return (
    <div className={cn('relative', rest.className)}>
      {label && (
        <span
          className={cn('block mb-1', {
            'absolute -top-2 left-3 px-1 bg-white z-20 !mb-0': dynamicLabel,
            'hidden': dynamicLabel && !value,
          })}
        >
          <Label
            text={label}
            className={cn({
              '!text-[#8D8D8D] !opacity-100 !font-normal !text-xs l:!text-xs':
                dynamicLabel,
            })}
          />
        </span>
      )}

      <input
        name={fieldName as string}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...rest}
        className={cn(
          'w-full flex items-center px-3 text-[12px] border border-grey-350 focus:border-blue-400 h-10 outline-none rounded-[5px] overflow-hidden text-ellipsis shadow-input-shadow',
          {
            'placeholder:!text-error': error && touched,
            '!border-error': error && touched,
          },
          inputClassName
        )}
        placeholder={error && touched ? `*${error}` : rest.placeholder}
        type={isPasswordShow ? 'text' : rest.type}
      />

      {rest.type === 'password' && (
        <button
          type="button"
          onClick={() => setPasswordShow(!isPasswordShow)}
          className="absolute bottom-1/4 right-6 hover:opacity-70 active:brightness-90"
        >
          <Icon
            icon={Icons.Eye}
            size={24}
            className={cn({
              'text-grey-550': !isPasswordShow,
              'text-black-700': isPasswordShow,
            })}
          />
        </button>
      )}
    </div>
  );
};
