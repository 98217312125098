import { useBreakpoints, useClickAway } from '@hooks';
import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  filtersActions,
  menuActions,
  menuSelectors,
  userActions,
} from '@store/entities';
import { useDispatch, useSelector } from '@store';
import { MobileHeader, MobileSidebar, Sidebar } from '@widgets';

export const Root: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { isDesktop } = useBreakpoints();
  const isOpen = useSelector(menuSelectors.isOpen);

  const exceptionRef = useRef(null);

  const ref = useClickAway(
    () => dispatch(menuActions.closeMenu()),
    [exceptionRef]
  );

  useEffect(() => {
    if (!isDesktop) {
      dispatch(menuActions.closeMenu());
    }
  }, [pathname]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
    } else {
      const tokenData = JSON.parse(token);
      dispatch(filtersActions.setSelectedLocations(tokenData.locations as any));
      dispatch(
        userActions.setUserData({
          email: tokenData.email,
        })
      );
    }
  }, []);

  return (
    <div className="flex h-screen overflow-hidden bg-grey-100">
      {isDesktop ? <Sidebar /> : <MobileSidebar ref={ref} isOpen={isOpen} />}

      <div className="flex flex-col flex-1 w-[calc(100vw-270px)] overflow-auto pt-16 l:pt-10">
        {!isDesktop && <MobileHeader toggleBtnRef={exceptionRef} />}

        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
