import React, { FC } from 'react';
import { SalesMetric } from './SalesMetric';
import { OrdersStatistics } from '@api';

interface SalesDetailsProps {
  metrics: OrdersStatistics;
}

export const SalesDetails: FC<SalesDetailsProps> = ({
  metrics: { totalSales, salesVolume, averageTicketValue },
}) => (
  <div className="flex items-center justify-start gap-x-10">
    <SalesMetric title="Total sales" value={totalSales} prefix="$" />

    <div className="w-px h-[60px] bg-black-700 opacity-10" />

    <SalesMetric title="Sales volume" value={salesVolume} />

    <div className="w-px h-[60px] bg-black-700 opacity-10" />

    <SalesMetric
      title="Average ticket value"
      value={averageTicketValue}
      prefix="$"
    />
  </div>
);
