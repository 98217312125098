import { createApi } from '@reduxjs/toolkit/query/react';

import { HTTPMethod, Response } from '../config';
import { baseQuery } from '../baseQuery';
import qs from 'qs';

import { Refund, RefundsRequest, RefundsResponse } from './models';

export const REFUNDS_LIMIT = 10;

export const refundsApi = createApi({
  baseQuery,
  reducerPath: 'refundsApi',
  tagTypes: ['Refunds'],
  endpoints: (builder) => ({
    getRefunds: builder.query<RefundsResponse, RefundsRequest>({
      query: ({ page, startDate, endDate, locations }) => {
        const queryString = qs.stringify(
          {
            limit: REFUNDS_LIMIT,
            page,
            starting_date: startDate,
            ending_date: endDate,
            locations,
          },
          { arrayFormat: 'repeat' }
        );
        return {
          url: `/refunds?${queryString}`,
          method: HTTPMethod.GET,
        };
      },
      providesTags: ['Refunds'],
      transformResponse: (response: Response<Refund[]>): RefundsResponse => {
        // console.log("ss", response); // Logging the data
        return {
          refunds: response.data,
          total: response.pagination?.total_items || 0,
        };
      },
    }),
  }),
});

export const { useGetRefundsQuery } = refundsApi;
