/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useRef, useState, KeyboardEvent } from 'react';

import { DatePicker, Page } from '@ui';
import 'react-data-grid/lib/styles.css';
import DataGrid, { Column, DataGridHandle, textEditor } from 'react-data-grid';
import { differenceInMinutes, format, isValid, parse, set } from 'date-fns';
import ReactSelect from 'react-select';

interface Row {
  id: number;
  employeeName: string;
  shiftStart?: string;
  shiftEnd?: string;
  hours?: number;
  breakTime?: number;
  paid?: number;
  notes: string;
}

const MINUTES_IN_A_DAY = 1440;

function getHourFormat(hourString?: string) {
  if (!hourString) {
    return '';
  }

  const hourDateFormat1 = parse(hourString, 'h:mm a', new Date());
  if (isValid(hourDateFormat1)) {
    return 'h:mm a';
  }

  const hourDateFormat2 = parse(hourString, 'h:mma', new Date());
  if (isValid(hourDateFormat2)) {
    return 'h:mma';
  }

  const hourDateFormat3 = parse(hourString, 'ha', new Date());
  if (isValid(hourDateFormat3)) {
    return 'ha';
  }

  const hourDateFormat4 = parse(hourString, 'h a', new Date());
  if (isValid(hourDateFormat4)) {
    return 'h a';
  }

  return '';
}

function validateHourStringFormat(hourString?: string) {
  if (!hourString) {
    return '';
  }

  const hourFormat = getHourFormat(hourString);
  if (!hourFormat) {
    return 'INVALID';
  }

  if (hourFormat !== 'h:mm a') {
    const parseHour = parse(hourString, hourFormat, new Date());
    return format(parseHour, 'h:mm a');
  }
  return hourString;
}

function getMinuteDifference(start?: string, end?: string) {
  if (!start || !end) {
    return undefined;
  }

  if (
    validateHourStringFormat(start) === 'INVALID' ||
    validateHourStringFormat(end) === 'INVALID'
  ) {
    return undefined;
  }

  const startHourFormat = getHourFormat(start);
  const endHourFormat = getHourFormat(end);

  const startDate = parse(start, startHourFormat, new Date());
  const endDate = parse(end, endHourFormat, new Date());

  const minutes = differenceInMinutes(endDate, startDate);
  if (minutes < 0) {
    return MINUTES_IN_A_DAY + minutes;
  }
  return minutes;
}

function calculateHours(start?: string, end?: string) {
  const minutes = getMinuteDifference(start, end);
  if (!minutes) {
    return undefined;
  }

  return parseFloat((minutes / 60).toFixed(2));
}

function calculateBreakTime(start?: string, end?: string) {
  const minutes = getMinuteDifference(start, end);

  if (!minutes) {
    return undefined;
  }

  if (minutes > 0 && minutes < 300) {
    return 0;
  }

  return 30;
}

function calculatePaidHours(hours?: number, breakTime?: number) {
  if (!hours) {
    return undefined;
  }

  if (!breakTime) {
    return hours;
  }
  return hours - breakTime / 60;
}

const employeeSalaries = [
  {
    name: 'Joe Vassallo Sr',
  },
  {
    name: 'Joe Vassallo',
  },
  {
    name: 'Monika Hidvegi',
  },
  {
    name: 'Noman Virani',
  },
  {
    name: 'Nooruddin Virani',
  },
  {
    name: 'Aaron Jacobs',
  },
  {
    name: '1 Sandeep Brar',
  },
  {
    name: '42 Malik Malik',
  },
  {
    name: '50 Inderpreet Inderpreet',
  },
  {
    name: '81 Ruhi Ruhi',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '234 Subham Singh',
  },
  {
    name: '245 Asmita Sharma',
  },
  {
    name: 'S Patel',
  },
  {
    name: '260 Anjali Patel',
  },
  {
    name: '261 Nidhi Sanjay',
  },
  {
    name: '268 Ashwani Rana',
  },
  {
    name: '289 Ma Luisa V',
  },
  {
    name: '286 Gunjan K',
  },
  {
    name: '298 Safalta Kc',
  },
  {
    name: '305 Milan Bohara',
  },
  {
    name: '311 Jaimin Nayak',
  },
  {
    name: '313 Pallavi Kumari Jha',
  },
  {
    name: '316 Ramanpreet Kaur',
  },
  {
    name: '321 Sujata Khadka',
  },
  {
    name: '327 Navin Kumar',
  },
  {
    name: '332 Samana Gc',
  },
  {
    name: '334 Suraj Khadka',
  },
  {
    name: '338 Babita Kaur',
  },
  {
    name: 'Joe Vassallo Sr',
  },
  {
    name: 'Joe Vassallo',
  },
  {
    name: 'Monika Hidvegi',
  },
  {
    name: 'Noman Virani',
  },
  {
    name: '66 Navneet Sran',
  },
  {
    name: '63 Amanmeet Singh',
  },
  {
    name: 'Nadia Virani',
  },
  {
    name: '19 Seema Vishiisht',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '159 Ansh Anand',
  },
  {
    name: '193 Nishant Jamba',
  },
  {
    name: '174 Kajal Kajal',
  },
  {
    name: 'Aaron Jacobs',
  },
  {
    name: '16 Shital Vaghela',
  },
  {
    name: '49 Inayat Inayat',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: 'Kiran Virani',
  },
  {
    name: '157 Parth Mistry',
  },
  {
    name: '202 Simranjit (Woodbridge) Kaur',
  },
  {
    name: '218 Kamal Preet',
  },
  {
    name: '224 Shally Kamal Preet',
  },
  {
    name: '230 Tushar Tushar',
  },
  {
    name: '243 Gurleen Kaur',
  },
  {
    name: '244 Muskan Mahejan',
  },
  {
    name: '252 Shipra Sharma',
  },
  {
    name: '256 Noopur Patel',
  },
  {
    name: '261 Nidhi Sanjay',
  },
  {
    name: '264 Manisha Goswani',
  },
  {
    name: '275 Akashdeep Kaur',
  },
  {
    name: '279 Kavya Nair',
  },
  {
    name: '291 Manbeer Manbeer',
  },
  {
    name: '300 Mohit Patil',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '296 Aishveen Kaur',
  },
  {
    name: '301 Amit Sharma',
  },
  {
    name: '299 Jatin Kumar',
  },
  {
    name: '307 Keziah Grace',
  },
  {
    name: '314 Manikesh Manikesh',
  },
  {
    name: '320 Rashi Batral',
  },
  {
    name: '325 Namrata Shrestha',
  },
  {
    name: '334 Suraj Khadka',
  },
  {
    name: 'Joe Vassallo Sr',
  },
  {
    name: 'Joe Vassallo',
  },
  {
    name: 'Monika Hidvegi',
  },
  {
    name: 'Noman Virani',
  },
  {
    name: '17 Amanpreet Kaur',
  },
  {
    name: '66 Navneet Sran',
  },
  {
    name: 'Nadia Virani',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '169 Ramandeep Kaur',
  },
  {
    name: 'Aaron Jacobs',
  },
  {
    name: '81 Ruhi Ruhi',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '157 Parth Mistry',
  },
  {
    name: '67 Parul Saini',
  },
  {
    name: '224 Shally Kamal Preet',
  },
  {
    name: '230 Tushar Tushar',
  },
  {
    name: '252 Shipra Sharma',
  },
  {
    name: '253 Dhandeep Singh',
  },
  {
    name: '256 Noopur Patel',
  },
  {
    name: '261 Nidhi Sanjay',
  },
  {
    name: '264 Manisha Goswani',
  },
  {
    name: '270 Urvashi Khosla',
  },
  {
    name: '279 Kavya Nair',
  },
  {
    name: '303 Manish Shrestha',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '307 Keziah Grace',
  },
  {
    name: '310 Jeel Mavani',
  },
  {
    name: '314 Manikesh Manikesh',
  },
  {
    name: '317 Jaskaranjot Singh',
  },
  {
    name: '320 Rashi Batral',
  },
  {
    name: '321 Sujata Khadka',
  },
  {
    name: '324 Iskila Rai',
  },
  {
    name: '335 Sumrit Singh',
  },
  {
    name: '334 Suraj Khadka',
  },
  {
    name: 'Noman Virani',
  },
  {
    name: '66 Navneet Sran',
  },
  {
    name: 'Nadia Virani',
  },
  {
    name: 'Nooruddin Virani',
  },
  {
    name: '19 Seema Vishiisht',
  },
  {
    name: '147 Manjeet Samra',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '160 Kiran Kaur',
  },
  {
    name: '159 Ansh Anand',
  },
  {
    name: '167 Shivani Shivani',
  },
  {
    name: '179 Ashwani Bamsal',
  },
  {
    name: '175 Jaspreet Singh',
  },
  {
    name: '193 Nishant Jamba',
  },
  {
    name: '190 Suman Preet',
  },
  {
    name: '189 Robinpreet Kaur',
  },
  {
    name: '207 Jasleen Kaur',
  },
  {
    name: '206 Anshika Anshika',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '212 Gagandeep Kaur',
  },
  {
    name: '215 Kenal Patel',
  },
  {
    name: '219 Arashdeep Singh',
  },
  {
    name: '218 Kamal Preet',
  },
  {
    name: '224 Shally Kamal Preet',
  },
  {
    name: '242 Prabhdeep Singh',
  },
  {
    name: '240 Kajal Bhalla',
  },
  {
    name: '243 Gurleen Kaur',
  },
  {
    name: '244 Muskan Mahejan',
  },
  {
    name: '246 Devarsh Agrawal',
  },
  {
    name: '247 Bikramjeet Singh',
  },
  {
    name: '248 Bhatti Gurleen',
  },
  {
    name: '254 Parampreet Kaur',
  },
  {
    name: '262 Dhwani Soni',
  },
  {
    name: '275 Akashdeep Kaur',
  },
  {
    name: '274 Sushma Anumalla',
  },
  {
    name: '280 Saran Reddy',
  },
  {
    name: '287 Ameeb Malik',
  },
  {
    name: '288 Husandeep Singh',
  },
  {
    name: '291 Manbeer Manbeer',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '296 Aishveen Kaur',
  },
  {
    name: '299 Jatin Kumar',
  },
  {
    name: '307 Keziah Grace',
  },
  {
    name: '309 Ramneet Kaur',
  },
  {
    name: '310 Jeel Mavani',
  },
  {
    name: '320 Rashi Batral',
  },
  {
    name: '325 Namrata Shrestha',
  },
  {
    name: '330 Rahul Rahul',
  },
  {
    name: '336 Gurinder Uppal',
  },
  {
    name: '333 Harpreet Sabhra Kaur',
  },
  {
    name: '339 Jasleen Jasleen Kaur',
  },
  {
    name: 'Noman Virani',
  },
  {
    name: '66 Navneet Sran',
  },
  {
    name: 'Nadia Virani',
  },
  {
    name: 'Nooruddin Virani',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '154 Masum Masum',
  },
  {
    name: '160 Kiran Kaur',
  },
  {
    name: '159 Ansh Anand',
  },
  {
    name: '167 Shivani Shivani',
  },
  {
    name: '169 Ramandeep Kaur',
  },
  {
    name: '179 Ashwani Bamsal',
  },
  {
    name: '175 Jaspreet Singh',
  },
  {
    name: '193 Nishant Jamba',
  },
  {
    name: '190 Suman Preet',
  },
  {
    name: '189 Robinpreet Kaur',
  },
  {
    name: '207 Jasleen Kaur',
  },
  {
    name: '206 Anshika Anshika',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '202 Simranjit (Woodbridge) Kaur',
  },
  {
    name: '212 Gagandeep Kaur',
  },
  {
    name: '215 Kenal Patel',
  },
  {
    name: '219 Arashdeep Singh',
  },
  {
    name: '218 Kamal Preet',
  },
  {
    name: '224 Shally Kamal Preet',
  },
  {
    name: '242 Prabhdeep Singh',
  },
  {
    name: '240 Kajal Bhalla',
  },
  {
    name: '243 Gurleen Kaur',
  },
  {
    name: '244 Muskan Mahejan',
  },
  {
    name: '246 Devarsh Agrawal',
  },
  {
    name: '247 Bikramjeet Singh',
  },
  {
    name: '248 Bhatti Gurleen',
  },
  {
    name: '254 Parampreet Kaur',
  },
  {
    name: '262 Dhwani Soni',
  },
  {
    name: '275 Akashdeep Kaur',
  },
  {
    name: '274 Sushma Anumalla',
  },
  {
    name: '288 Husandeep Singh',
  },
  {
    name: '291 Manbeer Manbeer',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '296 Aishveen Kaur',
  },
  {
    name: '299 Jatin Kumar',
  },
  {
    name: '309 Ramneet Kaur',
  },
  {
    name: '310 Jeel Mavani',
  },
  {
    name: '330 Rahul Rahul',
  },
  {
    name: '336 Gurinder Uppal',
  },
  {
    name: '333 Harpreet Sabhra Kaur',
  },
  {
    name: '339 Jasleen Jasleen Kaur',
  },
  {
    name: '17 Amanpreet Kaur',
  },
  {
    name: '66 Navneet Sran',
  },
  {
    name: 'Nadia Virani',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '154 Masum Masum',
  },
  {
    name: '160 Kiran Kaur',
  },
  {
    name: '159 Ansh Anand',
  },
  {
    name: '180 Manpreet Kaur',
  },
  {
    name: '179 Ashwani Bamsal',
  },
  {
    name: '175 Jaspreet Singh',
  },
  {
    name: '193 Nishant Jamba',
  },
  {
    name: '190 Suman Preet',
  },
  {
    name: '189 Robinpreet Kaur',
  },
  {
    name: '207 Jasleen Kaur',
  },
  {
    name: '206 Anshika Anshika',
  },
  {
    name: '276 Gurbinder "Gogo" Kaur',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '212 Gagandeep Kaur',
  },
  {
    name: '215 Kenal Patel',
  },
  {
    name: '219 Arashdeep Singh',
  },
  {
    name: '218 Kamal Preet',
  },
  {
    name: '224 Shally Kamal Preet',
  },
  {
    name: '242 Prabhdeep Singh',
  },
  {
    name: '240 Kajal Bhalla',
  },
  {
    name: '243 Gurleen Kaur',
  },
  {
    name: '244 Muskan Mahejan',
  },
  {
    name: '246 Devarsh Agrawal',
  },
  {
    name: '247 Bikramjeet Singh',
  },
  {
    name: '254 Parampreet Kaur',
  },
  {
    name: '262 Dhwani Soni',
  },
  {
    name: '275 Akashdeep Kaur',
  },
  {
    name: '274 Sushma Anumalla',
  },
  {
    name: '288 Husandeep Singh',
  },
  {
    name: '291 Manbeer Manbeer',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '296 Aishveen Kaur',
  },
  {
    name: '299 Jatin Kumar',
  },
  {
    name: '309 Ramneet Kaur',
  },
  {
    name: '310 Jeel Mavani',
  },
  {
    name: '330 Rahul Rahul',
  },
  {
    name: '336 Gurinder Uppal',
  },
  {
    name: '333 Harpreet Sabhra Kaur',
  },
  {
    name: '334 Suraj Khadka',
  },
  {
    name: '339 Jasleen Jasleen Kaur',
  },
  {
    name: '38 Simarpreet Singh',
  },
  {
    name: '159 Ansh Anand',
  },
  {
    name: '175 Jaspreet Singh',
  },
  {
    name: '206 Anshika Anshika',
  },
  {
    name: 'Angoor Patel',
  },
  {
    name: '251 Manpret Manpreet',
  },
  {
    name: '212 Gagandeep Kaur',
  },
  {
    name: '215 Kenal Patel',
  },
  {
    name: '219 Arashdeep Singh',
  },
  {
    name: '218 Kamal Preet',
  },
  {
    name: '244 Muskan Mahejan',
  },
  {
    name: '246 Devarsh Agrawal',
  },
  {
    name: '247 Bikramjeet Singh',
  },
  {
    name: '248 Bhatti Gurleen',
  },
  {
    name: '254 Parampreet Kaur',
  },
  {
    name: '262 Dhwani Soni',
  },
  {
    name: '275 Akashdeep Kaur',
  },
  {
    name: '291 Manbeer Manbeer',
  },
  {
    name: '302 Udhav Sharma',
  },
  {
    name: '296 Aishveen Kaur',
  },
  {
    name: '299 Jatin Kumar',
  },
  {
    name: '309 Ramneet Kaur',
  },
  {
    name: '320 Rashi Batral',
  },
  {
    name: '330 Rahul Rahul',
  },
  {
    name: '336 Gurinder Uppal',
  },
  {
    name: '333 Harpreet Sabhra Kaur',
  },
  {
    name: '339 Jasleen Jasleen Kaur',
  },
];

const dummyRows: Row[] = [
  ...Array.from({ length: 25 }, (_, i) => ({
    id: i + 1,
    employeeName: '',
    shiftStart: '',
    shiftEnd: '',
    notes: '',
  })),
];

export const StaffTimeTracker: FC = () => {
  const gridRef = useRef<DataGridHandle>(null);
  const [rows, setRows] = useState(dummyRows);

  const columns: Column<Row>[] = [
    {
      key: 'id',
      name: '#',
      width: 50,
      cellClass: (row) => {
        return `${
          (row as never as { css: string }).css
        } border border-r-0 border-t-0`;
      },
    },
    {
      key: 'employeeName',
      name: 'Employee',
      width: 250,
      cellClass: (row) => {
        return `${
          (row as never as { css: string }).css
        } border border-r-0 border-t-0 overflow-visible`;
      },
      editable: true,
      renderEditCell: (p) => {
        return (
          <ReactSelect
            autoFocus
            className="basic-single"
            classNamePrefix="select"
            onChange={(e) => {
              p.onRowChange({ ...p.row, employeeName: e?.value || '' }, true);
            }}
            defaultValue={
              p.row.employeeName
                ? { value: p.row.employeeName, label: p.row.employeeName }
                : null
            }
            isSearchable
            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
              if (
                p.column.key === 'employeeName' &&
                (event.key === 'ArrowRight' ||
                  event.key === 'ArrowDown' ||
                  event.key === 'ArrowLeft' ||
                  event.key === 'ArrowUp')
              ) {
                const target = event.target as HTMLInputElement;
                if (target.value === '') {
                  event.preventDefault();
                  if (gridRef.current) {
                    const rowIdx = p.row.id - 1;
                    if (event.key === 'ArrowRight') {
                      gridRef.current.selectCell({
                        idx: p.column.idx + 1,
                        rowIdx,
                      });
                    }
                    if (event.key === 'ArrowDown') {
                      gridRef.current.selectCell({
                        idx: p.column.idx,
                        rowIdx: rowIdx + 1,
                      });
                    }
                    if (event.key === 'ArrowUp') {
                      gridRef.current.selectCell({
                        idx: p.column.idx,
                        rowIdx: rowIdx - 1,
                      });
                    }
                  }
                }
              }
            }}
            isClearable
            name="name"
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: '34px',
                height: '34px',
              }),
              valueContainer: (provided) => ({
                ...provided,
                height: '34px',
                padding: '0 6px',
              }),
              input: (provided) => ({
                ...provided,
                margin: '0px',
              }),
              indicatorSeparator: () => ({
                display: 'none',
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                height: '34px',
              }),
              option: (styles) => {
                return {
                  ...styles,
                  color: 'black',
                };
              },
            }}
            options={employeeSalaries.map((employee) => ({
              value: employee.name,
              label: employee.name,
            }))}
          />
        );
      },
    },
    {
      key: 'shiftStart',
      name: 'Shift Start',
      width: 100,
      editable: true,
      cellClass: (row) => {
        return `${
          (row as never as { css: string }).css
        } border border-r-0 border-t-0`;
      },
      renderCell(props) {
        return validateHourStringFormat(props.row.shiftStart);
      },
      renderEditCell: (props) =>
        textEditor({
          ...props,
          onRowChange: (editedRow, commit) => {
            const hours = calculateHours(
              editedRow.shiftStart,
              editedRow.shiftEnd
            );
            const breakTime = calculateBreakTime(
              editedRow.shiftStart,
              editedRow.shiftEnd
            );
            return props.onRowChange(
              {
                ...editedRow,
                hours,
                breakTime,
                paid: calculatePaidHours(hours, breakTime),
              },
              commit
            );
          },
        }),
    },
    {
      key: 'shiftEnd',
      name: 'Shift End',
      width: 100,
      editable: true,
      cellClass: (row) => {
        return `${
          (row as never as { css: string }).css
        } border border-r-0 border-t-0`;
      },
      renderCell(props) {
        return validateHourStringFormat(props.row.shiftEnd);
      },
      renderEditCell: (props) =>
        textEditor({
          ...props,
          onRowChange: (editedRow, commit) => {
            const hours = calculateHours(
              editedRow.shiftStart,
              editedRow.shiftEnd
            );
            const breakTime = calculateBreakTime(
              editedRow.shiftStart,
              editedRow.shiftEnd
            );
            return props.onRowChange(
              {
                ...editedRow,
                hours,
                breakTime,
                paid: calculatePaidHours(hours, breakTime),
              },
              commit
            );
          },
        }),
    },
    {
      key: 'hours',
      name: 'Hours',
      width: 100,
      cellClass: (row) => {
        const isOver12Hours = row.hours && row.hours >= 12;
        return `${(row as never as { css: string }).css} border ${
          isOver12Hours ? 'bg-red-200 border-red-400' : 'border-r-0 border-t-0 '
        }`;
      },
      renderCell(props) {
        return props.row.hours ? props.row.hours.toFixed(2) : '';
      },
    },
    {
      key: 'breakTime',
      name: 'Break',
      width: 100,
      editable: true,
      editorOptions: {
        commitOnOutsideClick: true,
      },
      cellClass: (row) => {
        return `${
          (row as never as { css: string }).css
        } border border-r-0 border-t-0`;
      },
      renderCell(props) {
        return props.row.breakTime || '';
      },
      renderEditCell: (props) =>
        textEditor({
          ...props,
          onRowChange: (editedRow, commit) => {
            const hours = calculateHours(
              editedRow.shiftStart,
              editedRow.shiftEnd
            );
            return props.onRowChange(
              {
                ...editedRow,
                hours,
                breakTime: editedRow.breakTime,
                paid: calculatePaidHours(hours, editedRow.breakTime || 0),
              },
              commit
            );
          },
        }),
    },
    {
      key: 'paid',
      name: 'Paid',
      width: 100,
      cellClass: (row) => {
        return `${(row as never as { css: string }).css} border border-t-0`;
      },
      renderCell(props) {
        return props.row.paid ? props.row.paid.toFixed(2) : '';
      },
    },
  ];

  return (
    <Page title="Staff Time Tracker" withIndicator positive>
      <div className="mb-6">
        <DatePicker
          disableDaysAfter={new Date()}
          value={new Date()}
          onChange={() => {}}
        />
      </div>
      <div className="h-[calc(100vh-12rem)]">
        <DataGrid
          ref={gridRef}
          onCellClick={(args, event) => {
            if (args.column.editable) {
              event.preventGridDefault();
              args.selectCell(true);
            }
          }}
          onCellKeyDown={(args, event) => {
            if (
              event.key === 'Backspace' &&
              args.mode === 'SELECT' &&
              args.column.key === 'employeeName'
            ) {
              // eslint-disable-next-line no-param-reassign
              args.row.employeeName = '';
              args.selectCell({
                idx: args.column.idx,
                rowIdx: args.rowIdx,
              });
            }
            if (args.mode === 'EDIT' && args.column.key !== 'employeeName') {
              if (
                event.key === 'ArrowRight' ||
                event.key === 'ArrowDown' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowUp'
              ) {
                args.onClose(true);
                args.navigate();
              }
            }

            if (event.key === 'Enter' && args.mode === 'EDIT') {
              event.preventGridDefault();
              args.onClose(true);
              if (gridRef.current) {
                gridRef.current.selectCell({
                  idx: args.column.idx,
                  rowIdx: args.rowIdx + 1,
                });
              }
            }
          }}
          columns={columns}
          rows={rows}
          onRowsChange={setRows}
          className="rdg-light h-[calc(100vh-12rem)] w-[817px]"
        />
      </div>
    </Page>
  );
};
