import { DateRangeOption } from '../types';
import { SelectOption } from '@ui';
import { Hour } from '../types/date';

export const today = new Date();

export const dateRangeOptions: SelectOption<DateRangeOption>[] = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday', withDivider: true },
  { label: 'This Week', value: 'this-week' },
  { label: 'Last Week', value: 'last-week' },
  { label: 'Last 7 Days', value: 'last-7-days', withDivider: true },
  { label: 'This Month', value: 'this-month' },
  { label: 'Last Month', value: 'last-month' },
  { label: 'Last 30 Days', value: 'last-30-days', withDivider: true },
  { label: 'Last 3 Months', value: 'last-3-months' },
  { label: 'Last 6 Months', value: 'last-6-months', withDivider: true },
  { label: 'This Year', value: 'this-year' },
  { label: 'Last Year', value: 'last-year' },
  { label: 'Last 12 Months', value: 'last-12-months' },
  { label: 'Custom Date Range', value: 'custom-date-range' },
];

export const chartColors = [
  '#82BDDB',
  '#FF7E00',
  '#E14A39',
  '#553983',
  '#DE4B35',
  '#00A885',
  '#F4ED3D',
];

export const HOURS: Hour[] = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
];
