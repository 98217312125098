import { useWindowSize } from './useWindowSize';

export const useBreakpoints = () => {
  const { width } = useWindowSize();

  return {
    isMobile: width < 640,
    isTablet: width > 640 && width < 1024,
    isDesktop: width > 1024,
  };
};
