import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserSlice } from './types';
import { User, authApi } from '@api';

const initialState: UserSlice = {
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }: PayloadAction<User>) => {
      state.userData = payload;
    },
    logout: (state) => {
      state.userData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload.data;
      }
    );
  },
});

export const userActions = userSlice.actions;
