import React, { FC } from 'react';
import { IconType } from '../../../../utils';
import { Icon, Text } from '@ui';

interface NavAccordionHeaderProps {
  title: string;
  icon: IconType;
}

export const NavAccordionHeader: FC<NavAccordionHeaderProps> = ({
  title,
  icon,
}) => {
  return (
    <div className="flex items-center justify-start w-full px-4 py-3 text-white gap-x-2">
      <Icon icon={icon} />

      <Text variant="body1">{title}</Text>
    </div>
  );
};
