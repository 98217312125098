import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Route, Routes } from './Routes';

const ROUTE_ACCESS: {
  [key in Route]?: {
    allowAll: boolean;
    allowed?: string[];
    notAllowed?: string[];
  };
} = {
  [Routes.SALES_DASHBOARD]: {
    allowAll: true,
    notAllowed: ['hours@amigocloud.ca'],
  },
  [Routes.REPORTS]: {
    allowAll: true,
    notAllowed: ['hours@amigocloud.ca'],
  },
  [Routes.HOURLY_SALES_REPORT]: {
    allowAll: true,
    notAllowed: ['hours@amigocloud.ca'],
  },
  [Routes.TAX_REPORT]: {
    allowAll: true,
    notAllowed: ['hours@amigocloud.ca'],
  },
  [Routes.PAYMENT_TYPES_REPORT]: {
    allowAll: true,
    notAllowed: ['hours@amigocloud.ca'],
  },
  [Routes.STAFF_TIME_TRACKER]: {
    allowAll: false,
    allowed: ['hours@amigocloud.ca'],
  },

  [Routes.ROOT]: {
    allowAll: true,
  },
};

export function hasRouteAccess(route: Route, userEmail: string) {
  const routeAccess = ROUTE_ACCESS[route];
  if (routeAccess?.allowed?.includes(userEmail)) return true;
  if (routeAccess?.notAllowed?.includes(userEmail)) return false;
  if (routeAccess?.allowAll) return true;
  return false;
}

export const ProtectedRoutes = () => {
  const { pathname } = useLocation();
  const localStorageToken = localStorage.getItem('token');
  if (!localStorageToken) return <Navigate to="/login" replace />;

  if (localStorageToken) {
    const user = JSON.parse(localStorageToken);

    if (!hasRouteAccess(pathname as Route, user.email)) {
      return <Navigate to="/login" replace />;
    }
  }

  return <Outlet />;
};
