import { useBreakpoints } from '@hooks';
import React, { FC, ReactNode } from 'react';
import { Modal } from '../../Modal';

interface PickerContainerProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const PickerContainer: FC<PickerContainerProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const { isDesktop } = useBreakpoints();

  return isDesktop ? (
    children
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="!px-0 !pb-0 !pt-5 max-w-[327px]"
      headerClassName="px-4"
      overlayClassName="mt-16"
      title="Select Date"
    >
      {children}
    </Modal>
  );
};
