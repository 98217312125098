import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuery';
import { HTTPMethod, Response } from '../config';
import {
  UserBody,
  GetUsersRequest,
  UsersResponseData,
  UpdateUserRequest,
} from './models';
import { User } from '../models';

export const USERS_LIMIT = 7;

export const usersApi = createApi({
  baseQuery,
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query<UsersResponseData, GetUsersRequest>({
      query: (params) => ({
        url: '/users',
        method: HTTPMethod.GET,
        params: {
          ...params,
          limit: USERS_LIMIT,
        },
      }),
      providesTags: ['Users'],
      transformResponse: (response: Response<UsersResponseData>) =>
        response.data,
    }),
    getUser: builder.query<User, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: HTTPMethod.GET,
      }),
      transformResponse: (response: Response<User>) => response.data,
    }),
    createUser: builder.mutation<string, UserBody>({
      query: (user) => ({
        url: '/users',
        method: HTTPMethod.POST,
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<User, UpdateUserRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: HTTPMethod.PATCH,
        body,
      }),
      transformResponse: (response: Response<User>) => response.data,
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: HTTPMethod.DELETE,
      }),
      invalidatesTags: ['Users'],
    }),
    getUsersCSV: builder.mutation<string, string>({
      query: (search) => {
        return {
          url: '/users/csv',
          method: HTTPMethod.GET,
          params: {
            search,
          },
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useCreateUserMutation,
  useGetUsersCSVMutation,
} = usersApi;
