import React, { FC } from 'react';
import { SidebarItem as ISidebarItem } from '../config';
import { Link, useLocation } from 'react-router-dom';
import { Icon, Text } from '@ui';
import cn from 'classnames';
import { Icons } from '@utils';
import { useBreakpoints } from '@hooks';

interface SidebarItemProps extends ISidebarItem {
  isActive: boolean;
  className?: string;
  isNested?: boolean;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  route,
  isActive,
  icon,
  label,
  className,
  isNested,
}) => {
  const location = useLocation();
  const { isDesktop } = useBreakpoints();

  return (
    <Link
      to={{
        pathname: route || location.pathname,
      }}
    >
      <div
        className={cn(
          'relative w-full h-10 px-6 l:px-[14px] py-2.5 flex justify-start items-center gap-x-1.5 l:rounded-xl text-black-700 hover:bg-blue-400 hover:bg-opacity-[8%] hover:text-blue-400',
          {
            'bg-blue-400 bg-opacity-[8%] !text-blue-400': isActive,
            'rounded-xl !px-2': isNested,
          },
          className
        )}
      >
        {isNested && (
          <Icon
            icon={Icons.Line}
            className="absolute -top-[2px] -left-[18px] l:-left-[18.5px] text-black-700"
          />
        )}

        <div className="relative min-w-4 bottom-0.5">
          <Icon icon={icon} size={16} />
        </div>

        <Text
          variant={isDesktop ? 'body2' : 'body1'}
          className={cn('!text-inherit whitespace-nowrap !font-medium')}
        >
          {label}
        </Text>
      </div>
    </Link>
  );
};
