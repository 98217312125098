import React, { FC, SelectHTMLAttributes, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { Icons } from '@utils';
import cn from 'classnames';
import { Text } from '../Text';
import { useBreakpoints, useClickAway } from '@hooks';
import { Label } from '../Label';

export interface SelectOption<Value> {
  label: string;
  value: Value;
  withDivider?: boolean;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOption<any>[];
  label?: string;
  onChange: (value: any) => void;
  selectClassName?: string;
  labelClassName?: string;
  formSelect?: boolean;
}

export const Select: FC<SelectProps> = ({
  value,
  options,
  onChange,
  placeholder,
  className,
  selectClassName,
  labelClassName,
  formSelect,
  label,
}) => {
  const { isDesktop } = useBreakpoints();
  const [isShowOptions, setShowOptions] = useState(false);

  const buttonRef = useRef(null);
  const ref = useClickAway(() => setShowOptions(false), [buttonRef]);

  const getSelectValue = (newValue: any | any[]) =>
    options.find((option) => option.value === newValue) || null;

  const handleChange = (newValue: any) => {
    onChange(newValue);

    setShowOptions(false);
  };

  return (
    <div className={cn('relative w-full l:w-fit', className)}>
      {label && <Label text={label} className={cn('mb-1', labelClassName)} />}

      <button
        ref={buttonRef}
        type="button"
        onClick={() => setShowOptions(!isShowOptions)}
        className={cn(
          'w-full p-3 flex-between l:justify-center gap-x-1 bg-white border border-grey-400 rounded-[5px] shadow-input-shadow',
          {
            '!bg-grey-350': isShowOptions,
            'h-10': formSelect,
            'h-12 l:h-8': !formSelect,
          },
          selectClassName
        )}
      >
        <Text
          variant={isDesktop ? 'body2' : 'body1'}
          className={cn('whitespace-nowrap', {
            'text-grey-500': !getSelectValue(value)?.label,
          })}
        >
          {getSelectValue(value)?.label || placeholder}
        </Text>

        <Icon
          icon={Icons.ArrowDown}
          size={formSelect || isDesktop ? 16 : 24}
          className={cn('transition-transform ml-auto', {
            'rotate-180': isShowOptions,
          })}
        />
      </button>

      {isShowOptions && (
        <ul
          ref={ref}
          className="absolute left-0 z-20 w-full py-1 bg-white rounded-b shadow-menu-shadow l:min-w-fit top-17 l:top-13"
        >
          {options.map((option) => (
            <li key={option.value}>
              <div className="cursor-pointer hover:bg-grey-450">
                <button
                  type="button"
                  onClick={() => handleChange(option.value)}
                  className="list-item px-3 py-2 h-[30px] text-[12px] w-full text-left whitespace-nowrap"
                >
                  {option.label}
                </button>
              </div>

              {option.withDivider && (
                <div className="w-full h-px my-1 bg-grey-350" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
