export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface Response<Data> {
  data: Data;
  locations: Location[];
  pagination?: {
    page_size: number;
    current_page: number;
    total_items: number;
    total_pages: number;
  };
}
