import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { filtersSlice, menuSlice, userSlice } from './entities';
import {
  authApi,
  locationsApi,
  refundsApi,
  reportsApi,
  usersApi,
  voidsApi,
} from '../api';
import { ordersApi } from '../api/orders/ordersApi';

const userPersistConfig = {
  key: 'user',
  storage,
};

const rootReducer = combineReducers({
  filters: filtersSlice.reducer,
  menu: menuSlice.reducer,
  user: persistReducer(userPersistConfig, userSlice.reducer),
  [authApi.reducerPath]: authApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [refundsApi.reducerPath]: refundsApi.reducer,
  [voidsApi.reducerPath]: voidsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export { rootReducer };
