import { DateRange } from '@types';
import React, { FC } from 'react';
import { DayTimePicker } from '../DayTimePicker';
import { Icon } from '../../Icon';
import { Icons } from '@utils';
import { useBreakpoints } from '@hooks';

interface DateRangeProps {
  value: DateRange;
  onChange: (newValue: DateRange) => void;
}

export const DateRangePicker: FC<DateRangeProps> = ({ value, onChange }) => {
  const { isDesktop } = useBreakpoints();

  const icon = (
    <Icon
      icon={Icons.ArrowLeftRight}
      className="mt-4 rotate-90 l:rotate-0 text-grey-500 "
    />
  );

  return (
    <div className="flex flex-row items-center justify-start w-full l:items-center gap-x-2 l:w-fit">
      <div className="flex flex-col items-center justify-between w-full l:flex-row gap-y-4 gap-x-3">
        <DayTimePicker
          value={value.from}
          label="From"
          onChange={(from) => onChange({ ...value, from })}
          disabledAfter={value.to}
        />

        {isDesktop && icon}

        <DayTimePicker
          value={value.to}
          label="To"
          onChange={(to) => onChange({ ...value, to })}
          position="left"
          disabledBefore={value.from}
        />
      </div>

      {!isDesktop && icon}
    </div>
  );
};
