import { UserState } from './types';

const user = (state: UserState) => state.user.userData;

// const isAdmin = (state: UserState) => state.user.userData?.position === 'ADMIN';

export const userSelectors = {
  // isAdmin,
  user,
};
