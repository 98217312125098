import React from 'react';
import ReactDOM from 'react-dom/client';
import { reportWebVitals } from './app/analytics';
import { RoutesProvider } from '@navigation';

import './styles/index.css';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from '@store';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Toronto');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistStore(store)}>
            <RoutesProvider />
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

reportWebVitals();
