import { useClickAway } from '@hooks';
import { Icon, Text } from '@ui';
import { Icons } from '@utils';
import cn from 'classnames';
import React, { FC, useState } from 'react';

interface ActionMenuProps {
  className?: string;
  position?: 'top' | 'bottom';
  onEdit: () => void;
  onDelete: () => void;
}

export const ActionMenu: FC<ActionMenuProps> = ({
  className,
  position = 'bottom',
  onEdit,
  onDelete,
}) => {
  const [isOpen, setOpen] = useState(false);

  const ref = useClickAway(() => setOpen(false));

  return (
    <div className={cn('relative', className)}>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="p-3 flex-between gap-x-1 hover:brightness-75"
      >
        <div className="h-1.5 w-1.5 rounded-full bg-grey-450" />
        <div className="h-1.5 w-1.5 rounded-full bg-grey-450" />
        <div className="h-1.5 w-1.5 rounded-full bg-grey-450" />
      </button>

      {isOpen && (
        <ul
          ref={ref}
          className={cn(
            'absolute right-4 !bg-white rounded w-[160px] z-[100] shadow-menu-shadow',
            {
              'top-0': position === 'bottom',
              'bottom-0': position === 'top',
            }
          )}
        >
          <button
            type="button"
            onClick={onEdit}
            className="flex justify-start items-center px-[13px] pt-2 pb-3 hover:bg-grey-200 active:bg-grey-300 gap-x-[11px] w-full h-full"
          >
            <Icon icon={Icons.Pencil} size={14} />
            <Text variant="body1">Edit</Text>
          </button>
          <div className="w-full h-px bg-grey-300" />
          <button
            type="button"
            onClick={onDelete}
            className="flex justify-start items-center  gap-x-[11px] px-[13px] pb-2 pt-3 hover:bg-grey-200 active:bg-grey-300 text-error-light w-full h-full"
          >
            <Icon icon={Icons.Trash} size={14} />
            <Text variant="body1" className="text-error-light">
              Delete
            </Text>
          </button>
        </ul>
      )}
    </div>
  );
};
