import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from '@hooks';
import { HOURS, Icons } from '@utils';
import cn from 'classnames';

import { Icon } from '../../Icon';
import { Time } from '@types';
import { Label } from '../../Label';

interface TimePickerProps {
  label?: string;
  value: Time;
  className?: string;
  onChange: (time: Time) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label,
  value,
  className,
  onChange,
}) => {
  const hourButtonRef = useRef<HTMLButtonElement>(null);
  const amButtonRef = useRef<HTMLButtonElement>(null);

  const [isHourPickerVisible, setHourPickerVisible] = useState(false);
  const [isAMPickerVisible, setAmPickerVisible] = useState(false);

  const hoursRef = useClickAway(
    () => setHourPickerVisible(false),
    [hourButtonRef]
  );
  const amRef = useClickAway(() => setAmPickerVisible(false), [amButtonRef]);

  const [isAMSelected, setAMSelected] = useState(value.isAMSelected);
  const [selectedHour, setSelectedHour] = useState(value.selectedHour);

  useEffect(() => {
    onChange({ selectedHour, isAMSelected });
  }, [selectedHour, isAMSelected]);

  return (
    <div className={cn('relative', className)}>
      {label && <Label text={label} className="mb-2 l:mb-1" />}

      <div className="flex items-center justify-start gap-x-2">
        <div className="relative">
          <button
            ref={hourButtonRef}
            type="button"
            onClick={() => setHourPickerVisible(!isHourPickerVisible)}
            className={cn(
              'h-8 border border-grey-400 bg-white px-2 rounded-[5px] w-[100px] ',
              {
                '!border-blue-400': isHourPickerVisible,
              }
            )}
          >
            <div className={cn('flex items-center justify-between')}>
              <span
                className={cn(
                  'text-sm leading-[21px] mr-1.5 translate-y-[0.5px] whitespace-nowrap text-left'
                )}
              >
                {selectedHour}
              </span>

              <Icon
                icon={Icons.ArrowDown}
                size={16}
                className="text-grey-500"
              />
            </div>
          </button>

          {isHourPickerVisible && (
            <div
              ref={hoursRef}
              className={cn(
                'absolute z-[10] top-10 left-0 flex overflow-y-scroll h-[180px] w-full min-w-fit bg-white rounded-[5px] shadow-[0px_2px_4px_0_rgba(0,0,0,0.25)] custom-scroll'
              )}
            >
              <div className="flex flex-col w-full py-1">
                {HOURS.map((hour) => (
                  <div key={hour} className="w-full">
                    <button
                      type="button"
                      className="h-[30px] w-full pl-[10px] hover:bg-blue-400 hover:bg-opacity-10 text-left"
                      onClick={() => setSelectedHour(hour)}
                    >
                      <span
                        className={cn('text-sm', {
                          'text-fullfork': selectedHour === hour,
                        })}
                      >
                        {hour}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="relative">
          <button
            ref={amButtonRef}
            type="button"
            onClick={() => setAmPickerVisible(!isAMPickerVisible)}
            className={cn(
              'h-8 border border-grey-400 bg-white px-2 rounded-[5px] w-[56px]',
              {
                '!border-blue-400': isAMPickerVisible,
              }
            )}
          >
            <div className={cn('flex items-center justify-between')}>
              <span
                className={cn(
                  'text-sm leading-[21px] mr-1.5 translate-y-[0.5px] whitespace-nowrap text-left lowercase'
                )}
              >
                {isAMSelected ? 'AM' : 'PM'}
              </span>

              <Icon
                icon={Icons.ArrowDown}
                size={16}
                className="text-grey-500"
              />
            </div>
          </button>

          {isAMPickerVisible && (
            <div
              ref={amRef}
              className={cn(
                'absolute z-[10] top-10 left-0 flex overflow-y-auto w-full min-w-fit bg-white rounded-[5px] shadow-[0px_2px_4px_0_rgba(0,0,0,0.25)] custom-scroll'
              )}
            >
              <div className="w-full py-1">
                {['AM', 'PM'].map((amPm) => (
                  <div key={amPm}>
                    <button
                      type="button"
                      className="h-[30px] w-full hover:bg-blue-400 hover:bg-opacity-10 text-left"
                      onClick={() => setAMSelected(amPm === 'AM')}
                    >
                      <span
                        className={cn('text-sm px-2 lowercase', {
                          'text-blue-400':
                            (amPm === 'AM' && isAMSelected) ||
                            (amPm !== 'AM' && !isAMSelected),
                        })}
                      >
                        {amPm}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
